import React, { useState } from "react";
import { approveGatepassApi } from "../../../services/GatepassService";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";

const ApproveModal = ({ id, user, officerId, updateData }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = (show) => {
    setShowModal(show);
  };

  const handleApprove = async () => {
    try {
        const payload = { id: id };
        const response = await approveGatepassApi(payload);
        const { message, data } = response.data;
        updateData(data);
        toast.success(message);
        toggleModal(false);
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  const isButtonClickable = user?.id === officerId;

  return (
    <>
      <span
        className={`badge light border-0 badge-warning badge-sm ms-2 ${isButtonClickable ? 'cursor-pointer' : 'disabled'}`}
        onClick={() => isButtonClickable && toggleModal(true)}
      >
        Pending
      </span>
      <Modal
        className="modal modal-sm fade"
        id="exampleModal3"
        show={showModal}
        onHide={() => toggleModal(false)}
      >
        <div className="modal-content">
          <div className="modal-body">
            <p className="text-center">Are you sure you want to Approve this!</p>
            <div className="d-flex align-items-center justify-content-center">
              <button
                type="submit"
                className="btn btn-outline-primary btn-sm me-1"
                onClick={handleApprove}
                disabled={!isButtonClickable}
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps)(ApproveModal);
