import axiosInstance from './AxiosInstance';

const designationUrl = "api/designation"
const departmentUrl = "api/department"

// department
export function getDepartmentListApi(page, page_size, query) {
    return axiosInstance.get(`${departmentUrl}?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getDepartmentApi(id) {
    return axiosInstance.get(`${departmentUrl}/${id}`);
}

export function createDepartmentApi(data) {
    return axiosInstance.post(departmentUrl, data);
}

export function updateDepartmentApi(data) {
    return axiosInstance.put(departmentUrl, data);
}

// designation

export function getDesignationListApi() {
    return axiosInstance.get(`${designationUrl}`);
}

export function createDesignationApi(Data) {
    return axiosInstance.post(designationUrl, Data);
}

export function updateDesignationApi(Data) {
    return axiosInstance.put(designationUrl, Data);
}
