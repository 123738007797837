import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useNavigate, NavLink } from 'react-router-dom'
import { loadingToggleAction, loginAction } from '../../store/actions/AuthActions';
import { Spinner } from 'react-bootstrap';

import logo from '../../images/logo/logo_text.png';
import LogoWhite from '../../images/logo/logo.png';
import bg6 from '../../images/background/home_bg.png';

function Login({ showLoading }) {
	const navigate = useNavigate();
	const [email, setEmail] = useState('');
	let errorsObj = { email: '', password: '' };
	const [errors, setErrors] = useState(errorsObj);
	const [password, setPassword] = useState('');
	const dispatch = useDispatch();

	function onLogin(e) {

		e.preventDefault();
		if (!showLoading) {
			let error = false;
			const errorObj = { ...errorsObj };
			if (email === '') {
				errorObj.email = 'Email is Required';
				error = true;
			}
			if (password === '') {
				errorObj.password = 'Password is Required';
				error = true;
			}
			setErrors(errorObj);
			if (error) {
				return;
			}
			dispatch(loadingToggleAction(true));
			dispatch(loginAction(email, password, navigate));
		}
	}
	return (
		<div className="page-wraper">
			<div className="browse-job login-style3">
				<div className="bg-img-fix overflow-hidden" style={{ background: '#fff url(' + bg6 + ')', height: "100vh", }}>
					<div className="row gx-0">
						<div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 vh-100 bg-white ">
							<div id="mCSB_1" className="mCustomScrollBox mCS-light mCSB_vertical mCSB_inside" style={{ maxHeight: "653px" }}>
								<div id="mCSB_1_container" className="mCSB_container" style={{ position: "relative", top: "0", left: "0", dir: "ltr" }}>
									<div className="login-form style-2">
										<div className="card-body">
											<div style={{ marginBottom: '2rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
												<img src={LogoWhite} alt="" width={'50px'} className="width-200 " style={{ marginRight: '0.6rem' }} />
												<img src={logo} alt="" width={'150px'} className="width-200 light-logo" />
											</div>
											<div className="nav nav-tabs border-bottom-0" >
												<div className="tab-content w-100" id="nav-tabContent">
													<div className="tab-pane fade active show" id="nav-personal">
														<form className=" dz-form pb-3" onSubmit={onLogin}>
															<h3 className="form-title text-center m-t0">Login Into Your Account</h3>
															<div className="dz-separator-outer m-b5">
																<div className="dz-separator bg-primary style-liner"></div>
															</div>
															<p>Enter your e-mail address and password to continue. </p>
															<div className="form-group mb-3">
																<label>Email</label>
																<input type="email" className="form-control"
																	placeholder='Enter Email'
																	value={email} onChange={(e) => setEmail(e.target.value)} />
																{errors.email && <div className="text-danger fs-12">{errors.email}</div>}
															</div>
															<div className="form-group mb-3">
																<label>Password</label>
																<input type="password" className="form-control"
																	placeholder='Enter Password'
																	value={password} onChange={(e) => setPassword(e.target.value)} />
																{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
															</div>

															<button type="submit" disabled={showLoading} className="btn btn-primary btn-block dz-xs-flex m-r5">
																{showLoading ? <><Spinner size="sm" /> Login</> : "Login"}
															</button>
														</form>
													</div>

													<div className="d-flex align-items-center justify-content-between bottom">
														<NavLink to="/forgot-password" className="c-pointer mt-3" >
															Forgot Password
														</NavLink>

														<NavLink to="/register" className="btn btn-outline-primary btn-sm">
															Visitor Sign up
														</NavLink>
													</div>

												</div>
											</div>
										</div>
										<div className="card-footer border-top">
											<div className=" bottom-footer clearfix m-t10 m-b20 row text-center">
												<div className="col-lg-12 text-center">
													<span> © Copyright by <span className={`heart heart-blast`}></span>
														<a href="https://www.dexignzone.com/" rel="noreferrer" target="_blank"> Intelisparzk </a> All rights reserved.</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.auth.showLoading,
	};
};
export default connect(mapStateToProps)(Login);