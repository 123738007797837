export const dateFomatYMD = (dateObject) => {
    const isoString = dateObject.toISOString();
    return isoString.split("T")[0];

}


export const getInitialChar = (fullName) => {
    const nameArray = fullName.split(' ');
    // Get the first letter of the first name
    const firstNameInitial = nameArray[0].charAt(0);
    // Get the first letter of the last name (if available)
    const lastNameInitial = nameArray.length > 1 ? nameArray[nameArray.length - 1].charAt(0) : '';
    // Concatenate the initials
    const initials = firstNameInitial + lastNameInitial;
    return initials.toUpperCase(); // Convert to uppercase if needed
  }