import axios from 'axios'


const axiosInstance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_BASE_URL,
});

export function login(email, password) {
    const postData = {
        email,
        password,
    };
    return axiosInstance.post(`api/auth/login`, postData);
}

export function logoutApi(email, password) {
    const postData = {
        email,
        password,
    };
    return axiosInstance.get(`api/auth/logout`, postData);
}

export function whoami() {
    return axiosInstance.get('api/auth/authorized');
}


export function signupApi(data) {
    return axiosInstance.post(`api/auth/register`, data);
}

export function verifySignupOtpApi(data) {
    return axiosInstance.put(`api/auth/register`, data);
}