import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link, NavLink } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { signupApi } from "../../services/AuthService";
import { Spinner } from "react-bootstrap";
import VisitorSignupOtpForm from "./RegistrationOtpForm";
import { toast } from "react-toastify";
const SignupSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup
        .string()
        .email("Invalid email format")
        .required("Email is required"),
    phoneNumber: yup
        .string()
        .required("Phone number is required")
        .matches(/^[0-9]{10}$/, "Phone number must be 10 digits"),

    aadhaarNumber: yup
        .string()
        .matches(/^[0-9]{12}$/, "Aadhaar number must be 12 digits")
        .required("Aadhaar number is required"),
    dob: yup
        .string()
        .required("Date of Birth is required")
        .matches(
            /^\d{4}-\d{2}-\d{2}$/,
            "Date of Birth must be in the format YYYY-MM-DD"
        )
        .test("age", "Visitor must be at least 14 years old", function (value) {
            if (!value) return false;
            const today = new Date();
            const birthDate = new Date(value);
            const age = today.getFullYear() - birthDate.getFullYear();
            return age >= 14;
        }),
    gender: yup
        .string()
        .required("Gender is required"),
    companyName: yup.string().required("Company Name is required"),
    designation: yup.string().required("Designation is required"),
    profilePhoto: yup
        .mixed().nullable()
        .required("Profile photo is required")
        .test(
            "fileSize",
            "Profile photo must be less than 1MB",
            (value) => {
                if (!value) return false;
                return value && value?.size <= 1024 * 1024
            }
        )
        .test(
            "fileFormat",
            "Profile photo must be in JPG or PNG format",
            (value) => {
                if (!value) return false;
                return ["image/jpeg", "image/png"].includes(value?.type)
            }
        ),
    aadhaarPhoto: yup
        .mixed().nullable()
        .required("Aadhaar photo is required")
        .test(
            "fileSize",
            "Aadhaar photo must be less than 1MB",
            (value) => {
                if (!value) return false;
                return value?.size <= 1024 * 1024
            }
        ).test(
            "fileFormat",
            "Aadhaar photo must be in JPG or PNG format",
            (value) => {
                if (!value) return false;
                return ["image/jpeg", "image/png"].includes(value?.type)
            }
        ),
    password: yup
        .string()
        .required("Password is required")
        .min(8, "Password must be at least 8 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[0-9]/, "Password must contain at least one number"),
    terms_condtions: yup.boolean()
    .required("You must agree to the terms and conditions")
    .oneOf([true], "Please agree to the terms & conditions"),
});

const VisitorSignupForm = () => {
    const [captchaValid, setCaptchaValid] = useState(true);
    const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
    const [aadhaarPhotoPreview, setAadhaarPhotoPreview] = useState(null);
    const [otpVisible, setOtpVisible] = useState(false);
    const [loading, setLoadiing] = useState(false);
    const { register, handleSubmit, formState: { errors }, clearErrors, getValues, setValue, reset } = useForm({
        resolver: yupResolver(SignupSchema),
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            aadhaarNumber: '',
            dob: '',
            gender: '',
            companyName: '',
            designation: '',
            profilePhoto: null,
            aadhaarPhoto: null,
            password: '',
            terms_condtions: false
        }
    });

    const toggleOtpVisible = () => { setOtpVisible(!otpVisible) }


    const handleProfilePhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setProfilePhotoPreview(reader.result);
            };
            reader.readAsDataURL(file);
            setValue("profilePhoto", file);
            clearErrors("profilePhoto")
        }
    };

    const handleAadhaarPhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setAadhaarPhotoPreview(reader.result);
            };
            reader.readAsDataURL(file);
            setValue("aadhaarPhoto", file);
            clearErrors("aadhaarPhoto")
        }
    };

    const onSubmit = async (data) => {
        if (loading) {
            return false
        }
        if (captchaValid) {
            // Prepare form data for API submission
            const formData = new FormData();
            formData.append("first_name", data.firstName);
            formData.append("last_name", data.lastName);
            formData.append("email", data.email);
            formData.append("phone_number", data.phoneNumber);
            formData.append("aadhaar_number", data.aadhaarNumber);
            formData.append("dob", data.dob);
            formData.append("gender", data.gender);
            formData.append("company_name", data.companyName);
            formData.append("designation", data.designation);
            formData.append("profile_photo", data.profilePhoto);
            formData.append("aadhaar_photo", data.aadhaarPhoto);
            formData.append("password", data.password);
            setLoadiing(true)
            try {
                const resp = await signupApi(formData)
                const userId = resp.data.data.id
                setValue('id', userId)
                toggleOtpVisible()
                setLoadiing(false)
            } catch (error) {
                alert("Error during signup: " + error.message);
                setLoadiing(false)
            }
        } else {
            toast.error("Please verify the CAPTCHA");
        }
    };

    const onCaptchaChange = (value) => {
        setCaptchaValid(!!value);
    };

    const handleClear = () => {
        reset(); // Reset the form to default values
        setProfilePhotoPreview(null);
        setAadhaarPhotoPreview(null);
    }

    return (
        <div className="card-body" style={{ maxHeight: "80vh", overflowY: "auto" }}>
            <h3 className="text-center">{otpVisible ? "Verify OTP" : "Visitor Registration Form"}</h3>
            {otpVisible ? <VisitorSignupOtpForm data={getValues()} toggleOtpVisible={toggleOtpVisible} handleClear={handleClear} /> : (
                <form onSubmit={handleSubmit(onSubmit)}>

                    <div className="row">
                        <div className="form-group col-md-6">
                            <label className="form-label">First Name</label>
                            <input type="text" placeholder="Enter first name" className="form-control" {...register("firstName")} />
                            <p className="text-danger">{errors.firstName?.message}</p>
                        </div>

                        <div className="form-group col-md-6">
                            <label className="form-label">Last Name</label>
                            <input type="text" placeholder="Enter last name" className="form-control" {...register("lastName")} />
                            <p className="text-danger">{errors.lastName?.message}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-md-6">
                            <label className="form-label">Email</label>
                            <input type="email" autoComplete="off" placeholder="Enter email address" className="form-control" {...register("email")} />
                            <p className="text-danger">{errors.email?.message}</p>
                        </div>

                        <div className="form-group col-md-6">
                            <label className="form-label">Phone Number</label>
                            <input type="text" placeholder="Enter phone number" className="form-control" {...register("phoneNumber")} />
                            <p className="text-danger">{errors.phoneNumber?.message}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-md-6">
                            <label className="form-label">Date of Birth</label>
                            <input type="date" className="form-control"
                                pattern="\d{4}-\d{2}-\d{2}"
                                max={new Date().toISOString().split("T")[0]}  {...register("dob")} />
                            <p className="text-danger">{errors.dob?.message}</p>
                        </div>

                        <div className="form-group col-md-6">
                            <label className="form-label">Gender </label><br />

                            <div className="form-check custom-checkbox mb-2 form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    id="MaleRadio"
                                    value="Male"
                                    {...register("gender")}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="MaleRadio"
                                >
                                    Male
                                </label>
                            </div>
                            <div className="form-check custom-checkbox mb-2 form-check-inline">
                                <input
                                    type="radio"
                                    className="form-check-input"
                                    id="FemaleRadio"
                                    value="Female"
                                    {...register("gender")}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="FemaleRadio"
                                >
                                    Female
                                </label>
                            </div>
                            <p className="text-danger">{errors.gender?.message}</p>
                        </div>
                    </div>


                    <div className="row">
                        <div className="form-group col-md-6">
                            <label className="form-label">Aadhaar Number</label>
                            <input type="text" placeholder="Enter aadhaar number" className="form-control" {...register("aadhaarNumber")} />
                            <p className="text-danger">{errors.aadhaarNumber?.message}</p>
                        </div>

                        <div className="form-group col-md-6">
                            <label className="form-label">Password</label>
                            <input
                                type="password"
                                autoComplete="new-password"
                                className="form-control"
                                placeholder="Enter password"
                                {...register("password")}
                            />
                            <p className="text-danger">{errors.password?.message}</p>
                        </div>
                    </div>


                    <div className="row">
                        <div className="form-group col-md-6">
                            <label className="form-label">Company Name</label>
                            <input type="text" placeholder="Enter company name" className="form-control" {...register("companyName")} />
                            <p className="text-danger">{errors.companyName?.message}</p>
                        </div>

                        <div className="form-group col-md-6">
                            <label className="form-label">Designation</label>
                            <input type="text" placeholder="Enter designation" className="form-control" {...register("designation")} />
                            <p className="text-danger">{errors.designation?.message}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-md-6">
                            <label className="form-label">Profile Photo (JPG/PNG)</label>
                            <div
                                className="file-upload-box"
                                onClick={() => document.getElementById("profilePhotoInput").click()}
                            >
                                {profilePhotoPreview ? (
                                    <img src={profilePhotoPreview} alt="Profile Preview" />
                                ) : (
                                    <p>Click to upload</p>
                                )}
                            </div>
                            <input
                                id="profilePhotoInput"
                                type="file"
                                className="form-control"
                                style={{ display: "none" }}
                                accept="image/jpeg, image/png"
                                {...register("profilePhoto")}
                                onChange={handleProfilePhotoChange}
                            />
                            <p className="text-danger">{errors.profilePhoto?.message}</p>
                        </div>

                        <div className="form-group col-md-6">
                            <label className="form-label">Aadhaar Photo (JPG/PNG)</label>
                            <div
                                className="file-upload-box"
                                onClick={() => document.getElementById("aadhaarPhotoInput").click()}
                            >
                                {aadhaarPhotoPreview ? (
                                    <img src={aadhaarPhotoPreview} alt="Aadhaar Preview" />
                                ) : (
                                    <p>Click to upload</p>
                                )}
                            </div>
                            <input
                                id="aadhaarPhotoInput"
                                type="file"
                                className="form-control"
                                style={{ display: "none" }}
                                accept="image/jpeg, image/png"
                                {...register("aadhaarPhoto")}
                                onChange={handleAadhaarPhotoChange}
                            />
                            <p className="text-danger">{errors.aadhaarPhoto?.message}</p>
                        </div>
                    </div>

                    <div className="form-group">
                        <ReCAPTCHA sitekey="6Le3lDwqAAAAAFj3TdIIgA7EALQzqdpf2ZhoEiRM" size="invisible" onChange={onCaptchaChange} />
                    </div>
                    <div className="mb-3 mt-3">
                        <span className="form-check float-start me-2">
                            <input type="checkbox" {...register("terms_conditions")} className="form-check-input mt-0" id="check2" />
                            <label className="form-check-label d-unset" htmlFor="check2">I agree to the</label>
                        </span>
                        <label><Link to={"#"}>Terms of Service </Link>&amp; <Link to={"#"}>Privacy Policy</Link></label>
                        <p className="text-danger">{errors.terms_condtions?.message}</p>
                    </div>
                    <div className="form-group clearfix text-left">
                        <NavLink to="/" className="btn btn-outline-primary outline gray" type="button">Login</NavLink >
                        <button type="submit" disabled={loading} className="btn btn-primary float-end">
                            {loading ? <><Spinner size="sm" /> Submiting...</> : "Submit"}
                        </button>
                    </div>
                    {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                </form>
            )}
        </div>
    );
};

export default VisitorSignupForm;
