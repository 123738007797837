import axiosInstance from './AxiosInstance';
const gatepassUrl = "api/gatepass"
// user
export function getGatepassListApi(page, page_size, query) {
    return axiosInstance.get(`${gatepassUrl}?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getGatepassApi(id) {
    return axiosInstance.get(`${gatepassUrl}/${id}`);
}

export function createGatepassApi(data) {
    return axiosInstance.post(gatepassUrl, data);
}

export function updateGatepassApi(data) {
    return axiosInstance.put(gatepassUrl, data);
}

export function approveGatepassApi(data) {
    return axiosInstance.post("api/approve_gatepass", data);
}
