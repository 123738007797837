import React, { useState, useEffect, useCallback } from "react";
import { getGateListApi } from "../../../services/ProductService";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CancelRequest } from "../../../services/AxiosInstance";
import Pagination from "../../constant/Pagination";
import GateAddModal from "./AddGateModal";
import GateEditModal from "./EditGateModal";
import debounce from 'lodash.debounce';

const GatePage = ({ useRole }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const recordsPage = 10;
  const navigate = useNavigate();

  const fetchData = useCallback((page, query) => {
    setIsLoading(true);
    getGateListApi(page, recordsPage, query)
      .then((resp) => {
        setData(resp.data.data);
        setTotal(resp.data.total);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error.response?.data?.message || "Error fetching data");
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData(currentPage, searchQuery);
  }, [currentPage, searchQuery, fetchData]);

  const handlePagination = (page) => {
    setCurrentPage(page.selected + 1);
  };

  const handleSearchChange = debounce((value) => {
    if (value.length > 0) CancelRequest();
    setSearchQuery(value);
  }, 300);

  const handleAfterCreate = (newData) => {
    setData((prevData) => [newData, ...prevData]);
    setTotal((prevTotal) => prevTotal + 1);
  };

  const handleAfterUpdate = (updatedData) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.id === updatedData.id ? { ...item, ...updatedData } : item
      )
    );
  };


  return (
    <div className="container-fluid">
      <div className="card">
        <div className="d-flex align-items-start align-items-md-center flex-column flex-md-row justify-content-between p-3">
          <h4 className="heading mb-md-0 mb-2">Gate List</h4>
          <div className='d-flex align-items-start align-items-md-center flex-column flex-md-row justify-content-center'>
            <div className='form-group me-md-3 me-0 mb-2 mb-md-0'>
              <input
                type="search"
                name="search"
                className="form-control"
                placeholder="Search"
                onChange={(e) => handleSearchChange(e.target.value)}
              />
            </div>
            {useRole === "SuperAdmin" && (
              <GateAddModal handleAfterCreate={handleAfterCreate} />
            )}
          </div>
        </div>
      </div>

      {isLoading ? (
        <h3>Loading ...</h3>
      ) : (
        <div>
          <div className="row">
            {data.map((item, index) => (
              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6" key={index}>
                <div className="card">
                  <div className="card-body">
                    <h4>{item.name}</h4>
                    <h5 className="fw-light">Gate Type:  <strong className={`${item.gate_type === "Entry" ? 'text-success' : 'text-danger'}`}>{item.gate_type}</strong></h5>
                  </div>
                  <div className="card-footer d-flex align-item-center justify-content-between">
                    <button className="btn btn-outline-primary px-4 btn-sm">View Details</button>
                    <GateEditModal data={item} handleAfterUpdate={handleAfterUpdate} />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div>
            <Pagination
              currentPage={currentPage}
              recordsPage={recordsPage}
              dataLength={total}
              handlePagination={handlePagination}
            />
          </div>
        </div>
      )}
    </div >
  );
};

const mapStateToProps = (state) => ({
  useRole: state.auth.user?.role || "User",
});

export default connect(mapStateToProps)(GatePage);
