import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Placeholder } from "react-bootstrap";
import { connect } from "react-redux";
import { getUserApi } from "../../../services/UserService";
import UserEditModal from "./EditUserModal";
import TextAvatar from "../../constant/TextAvatar";
import { getInitialChar } from "../../../utils";

const UserDetailsLoading = () => {
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="w-100 px-2 mt-3">
            <Placeholder animation="glow">
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
            </Placeholder>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-item-center justify-content-between">
            <div className="w-100 px-2">
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
              </Placeholder>
            </div>

            <div className="w-100 px-2">
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
              </Placeholder>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function UserDetailsPage({ userRole }) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await getUserApi(id);
      const resp_data = resp.data.data;
      setData(resp_data);
    } catch (error) {
      console.error("Error fetching user:", error);
      setData(null);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleAfterUpdate = (updatedData) => {};

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            {isLoading ? (
              <UserDetailsLoading />
            ) : data !== null ? (
              <div>
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="d-flex  align-items-center">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm btn-circle"
                          onClick={() => navigate("/user")}
                        >
                          <i className="fa-solid fa-arrow-left"></i>
                        </button>
                        <h4 className="mb-0 ms-2">User Details</h4>
                      </div>
                      <div>
                        <UserEditModal
                          data={data}
                          handleAfterUpdate={handleAfterUpdate}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {data ? (
                      <div>
                          <div className="d-flex align-items-center justify-content-center flex-column border-bottom">
                            <div>
                              {data?.profile_photo ? (
                                <div className="profile-pic-lg">
                                  <img
                                    src={`${process.env.REACT_APP_BASE_URL}${data.profile_photo}`}
                                  />
                                </div>
                              ) : (
                                <TextAvatar text={getInitialChar(`${data.first_name} ${data.last_name}`)} clsName="text-avatar-square"/>
                              )}
                            </div>
                            <h3 className="my-2">{data.first_name} {data.last_name}</h3>
                          </div>
                        <ul>
                        <li className="my-3">
                            <span className="fw-bold">Email: </span>
                            {data.email}
                          </li>

                          <li className="my-3">
                            <span className="fw-bold">Phone Number: </span>
                            {data.phone_number}
                          </li>


                          <li className="my-3">
                            <span className="fw-bold">Status: </span>
                            <span
                              className={`badge light border-0 ${
                                data.status === "Active"
                                  ? "badge-success"
                                  : "badge-danger"
                              } badge-sm ms-2`}
                            >
                              {data.status}
                            </span>
                          </li>
                          <li className="my-3">
                            <span className="fw-bold">Designation: </span>
                            {data.designation?.name}
                          </li>
                          <li className="my-3">
                            <span className="fw-bold">Department: </span>
                            {data.department?.name}
                          </li>
                        </ul>
                      </div>
                    ) : null}
                  </div>
                </div>
                {(userRole === "Admin" && data?.role === "User") ||
                userRole === "SuperAdmin" ? (
                  <div className="card">
                    {/* <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between my-2 py-2">
                        <h5 className="mb-md-0">Change User Password</h5>
                        <ChangePassword />
                      </div>
                      <div className="d-flex align-items-center justify-content-between border-top my-2 py-3">
                        <DeactivateUserModal
                          data={{ id, status: data?.status || "Active" }}
                          setData={setData}
                        />
                      </div>
                    </div> */}
                  </div>
                ) : null}
              </div>
            ) : (
              <div className="card">
                <div className="card-body">
                  <h4 className="text-center text-danger">
                    ⚠️ User Not Found!
                    <br /> ID {id}
                  </h4>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-8">
            <div className="card"></div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    userRole: state.auth.user?.role || "Admin",
  };
};

export default connect(mapStateToProps)(UserDetailsPage);
