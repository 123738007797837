import React, { useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DynamicSelectInput from "../../constant/SelectInput";
import { createGatepassApi } from "../../../services/GatepassService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";

const GatepassForm = () => {
  // Define today date for comparison
  const today = new Date();

  const validationSchema = Yup.object().shape({
    visiting_start_date: Yup.string()
      .required("Visiting start date is required")
      .matches(
        /^\d{4}-\d{2}-\d{2}$/,
        "Visiting start date must be in the format YYYY-MM-DD"
      )
      .test(
        "is-valid-start-date",
        "Visiting start date must be greater than today",
        function (value) {
          if (value) {
            const startDate = new Date(value);
            return startDate >= new Date(today);
          }
          return false;
        }
      ),

    visiting_end_date: Yup.string()
      .required("Visiting end date is required")
      .matches(
        /^\d{4}-\d{2}-\d{2}$/,
        "Visiting end date must be in the format YYYY-MM-DD"
      )
      .test(
        "is-valid-end-date",
        "Visiting end date must be at most 3 days after the start date",
        function (value) {
          const { visiting_start_date } = this.parent;
          if (value && visiting_start_date) {
            const startDate = new Date(visiting_start_date);
            const endDate = new Date(value);
            const maxEndDate = new Date(startDate);
            maxEndDate.setDate(startDate.getDate() + 3);
            return endDate <= maxEndDate;
          }
          return true;
        }
      ),

    visiting_department: Yup.string().required(
      "Visiting department is required"
    ),
    officer_to_meet: Yup.string().required("Officer to meet is required"),

    vehicle_available: Yup.string().required("Select vehicle availability"),

    vehicle_number: Yup.string().when("vehicle_available", ([val], schema) => {
      return val === "YES"
        ? schema.required("Vehicle number is required")
        : schema.notRequired();
    }),

    driver_dl_number: Yup.string().when(
      "vehicle_available",
      ([val], schema) => {
        return val === "YES"
          ? schema.required("Driver DL number is required")
          : schema.notRequired();
      }
    ),

    drive_type: Yup.string().when("vehicle_available", ([val], schema) => {
      return val === "YES"
        ? schema.required("Select Drive Type")
        : schema.notRequired();
    }),

    driver_name: Yup.string().when("drive_type", ([val], schema) => {
      return val === "Other"
        ? schema.required("Driver name is required")
        : schema.notRequired();
    }),

    driver_aadhaar_number: Yup.string().when("drive_type", ([val], schema) => {
      return val === "Other"
        ? schema.required("Driver Aadhaar number is required")
        : schema.notRequired();
    }),

    driver_profile_photo: Yup.mixed()
      .nullable()
      .when("drive_type", ([val], schema) => {
        return val === "Other"
          ? schema
              .required("Driver Profile Photo is required")
              .test(
                "fileSize",
                "File size must be below 1MB",
                (value) => value && value.size <= 1024 * 1024
              )
              .test(
                "fileFormat",
                "Only JPG/PNG files are allowed",
                (value) =>
                  value && ["image/jpeg", "image/png"].includes(value.type)
              )
          : schema.notRequired();
      }),

    driver_aadhaar_photo: Yup.mixed()
      .nullable()
      .when("drive_type", ([val], schema) => {
        return val === "Other"
          ? schema
              .required("Driver Aadhaar Photo is required")
              .test(
                "fileSize",
                "File size must be below 1MB",
                (value) => value && value.size <= 1024 * 1024
              )
              .test(
                "fileFormat",
                "Only JPG/PNG files are allowed",
                (value) =>
                  value && ["image/jpeg", "image/png"].includes(value.type)
              )
          : schema.notRequired();
      }),

    coVisitors: Yup.array()
      .of(
        Yup.object().shape({
          first_name: Yup.string().required("First name is required"),
          last_name: Yup.string().required("Last name is required"),
          email: Yup.string()
            .email("Invalid email format")
            .required("Email is required"),
          phone_number: Yup.string()
            .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
            .required("Phone number is required"),
          aadhaar_number: Yup.string()
            .matches(/^\d{12}$/, "Aadhaar number must be exactly 12 digits")
            .required("Aadhaar is required"),
          dob: Yup.string()
            .required("Date of Birth is required")
            .matches(
              /^\d{4}-\d{2}-\d{2}$/,
              "Date of Birth must be in the format YYYY-MM-DD"
            )
            .test(
              "age",
              "Visitor must be at least 14 years old",
              function (value) {
                if (!value) return false;
                const today = new Date();
                const birthDate = new Date(value);
                const age = today.getFullYear() - birthDate.getFullYear();
                return age >= 14;
              }
            ),
          gender: Yup.string().required("Select gender"),
          company_name: Yup.string().required("Company name is required"),
          designation: Yup.string().required("Designation is required"),
          profile_photo: Yup.mixed()
            .nullable()
            .required("Profile photo is required")
            .test(
              "fileSize",
              "File size must be below 1MB",
              (value) => value && value.size <= 1024 * 1024
            )
            .test(
              "fileFormat",
              "Only JPG/PNG files are allowed",
              (value) =>
                value && ["image/jpeg", "image/png"].includes(value.type)
            ),
          aadhaar_photo: Yup.mixed()
            .nullable()
            .required("Aadhaar photo is required")
            .test(
              "fileSize",
              "File size must be below 1MB",
              (value) => value && value.size <= 1024 * 1024
            )
            .test(
              "fileFormat",
              "Only JPG/PNG files are allowed",
              (value) =>
                value && ["image/jpeg", "image/png"].includes(value.type)
            ),
        })
      )
      .test(
        "validate-tags",
        "Tags array should not be empty if tags are provided",
        (tags) => tags.length === 0 || tags.length > 0
      ),

    returnableItems: Yup.array()
      .of(
        Yup.object().shape({
          item_name: Yup.string().required("Item name is required"),
          item_description: Yup.string().required(
            "Item description is required"
          ),
          item_photo: Yup.mixed()
            .nullable()
            .required("Item photo is required")
            .test(
              "fileSize",
              "File size must be below 1MB",
              (value) => value && value.size <= 1024 * 1024
            )
            .test(
              "fileFormat",
              "Only JPG/PNG files are allowed",
              (value) =>
                value && ["image/jpeg", "image/png"].includes(value.type)
            ),
        })
      )
      .test(
        "validate-tags",
        "Tags array should not be empty if tags are provided",
        (tags) => tags.length === 0 || tags.length > 0
      ),
    purpose_of_visiting: Yup.string().required(
      "Purpose of visiting is required"
    ),
  });

  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    defaultValues: {
      visiting_start_date: "",
      visiting_end_date: "",
      visiting_department: "",
      officer_to_meet: "",
      vehicle_available: "",
      vehicle_number: "",
      driver_dl_number: "",
      drive_type: "",
      driver_name: "",
      driver_aadhaar_number: "",
      driver_profile_photo: null,
      driver_aadhaar_photo: null,
      coVisitors: [],
      returnableItems: [],
      purpose_of_visiting: "",
    },
    resolver: yupResolver(validationSchema),
    context: { coVisitors: [] },
  });

  const {
    fields: coVisitors,
    append: appendCoVisitor,
    remove: removeCoVisitor,
  } = useFieldArray({
    control,
    name: "coVisitors",
  });

  const {
    fields: returnableItems,
    append: appendReturnableItem,
    remove: removeReturnableItem,
  } = useFieldArray({
    control,
    name: "returnableItems",
  });

  const [profilePhotoPreviews, setProfilePhotoPreviews] = useState({});
  const [aadhaarPhotoPreviews, setAadhaarPhotoPreviews] = useState({});
  const [driverProfilePhotoPreviews, setDriverProfilePhotoPreviews] =
    useState("");
  const [driverAadhaarPhotoPreviews, setDriverAadhaarPhotoPreviews] =
    useState("");
  const [itemPhotoPreviews, setItemPhotoPreviews] = useState({});

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleProfilePhotoChange = (index, e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePhotoPreviews((prev) => ({
          ...prev,
          [index]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
      setValue(name, file);
      clearErrors(name);
    }
  };

  const handleAadhaarPhotoChange = (index, e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAadhaarPhotoPreviews((prev) => ({
          ...prev,
          [index]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
      setValue(name, file);
      clearErrors(name);
    }
  };

  const handleDriverProfilePhotoChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setDriverProfilePhotoPreviews(reader.result);
      };
      reader.readAsDataURL(file);
      setValue(name, file);
      clearErrors(name);
    }
  };

  const handleDriverAadhaarPhotoChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setDriverAadhaarPhotoPreviews(reader.result);
      };
      reader.readAsDataURL(file);
      setValue(name, file);
      clearErrors(name);
    }
  };

  const handleItemPhotoChange = (index, e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setItemPhotoPreviews((prev) => ({
          ...prev,
          [index]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
      setValue(name, file);
      clearErrors(name);
    }
  };

  const handleSelectChange = (name, value, _) => {
    setValue(name, value);
    clearErrors(name);
  };

  const onSubmit = async (data) => {
    console.log(data);
    try {
      const formData = new FormData();

      const appendArrayToFormData = (arr) => {
        arr.forEach((item) => {
          Object.keys(item).forEach((subKey) => {
            formData.append(subKey, item[subKey]);
          });
        });
      };

      formData.append("visiting_start_date", data.visiting_start_date);
      formData.append("visiting_end_date", data.visiting_end_date);
      formData.append("visiting_department", data.visiting_department);
      formData.append("officer_to_meet", data.officer_to_meet);
      formData.append("vehicle_available", data.vehicle_available);
      if (data.vehicle_available === "YES") {
        formData.append("vehicle_number", data.vehicle_number);
        formData.append("driver_dl_number", data.driver_dl_number);
        formData.append("drive_type", data.drive_type);
        if (data.drive_type === "Other") {
          formData.append("driver_name", data.driver_name);
          formData.append("driver_aadhaar_number", data.driver_aadhaar_number);
          formData.append("driver_profile_photo", data.driver_profile_photo);
          formData.append("driver_aadhaar_photo", data.driver_aadhaar_photo);
        }
      }
      appendArrayToFormData(data.coVisitors);
      appendArrayToFormData(data.returnableItems);
      formData.append("purpose_of_visiting", data.purpose_of_visiting);
      try {
        setLoading(true);
        const resp = await createGatepassApi(formData);
        toast.success(resp.data.message);
        navigate('/gatepass')
      } catch (error) {
        toast.error(error?.response?.data?.message || "Error creating gate");
      } finally {
        setLoading(false);
      }
    } catch (error) {
      console.error("Validation error:", error.message);
      console.error("Validation stack trace:", error.stack);
      if (error.inner) {
        error.inner.forEach((err) => {
          console.error("Validation error details:", err.path, err.message);
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {/* Gatepass Information */}
      <h4 className="mb-3">Visiting Details</h4>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group mb-3">
            <label className="form-label">Visiting Start Date</label>
            <input
              type="date"
              className="form-control"
              {...register("visiting_start_date")}
            />
            {errors.visiting_start_date && (
              <p className="text-danger">
                {errors.visiting_start_date?.message}
              </p>
            )}
          </div>

          <div className="form-group mb-3">
            <label className="form-label">Visiting End Date</label>
            <input
              type="date"
              className="form-control"
              {...register("visiting_end_date", {
                required: "End date is required",
              })}
            />
            {errors.visiting_end_date && (
              <p className="text-danger">{errors.visiting_end_date?.message}</p>
            )}
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group mb-3">
            <DynamicSelectInput
              parentClassName="mb-0"
              setParentInputValue={handleSelectChange}
              endpoint_name="search/visiting_department"
              name={"visiting_department"}
              label={"Visiting Department"}
              labelHint={false}
              id={"visiting_department"}
              placeholder={"Select Visiting Department"}
              isRequired={true}
            />
            {errors.visiting_department && (
              <p className="text-danger">
                {errors.visiting_department?.message}
              </p>
            )}
          </div>

          <div className="form-group mb-3">
            <DynamicSelectInput
              parentClassName="mb-0"
              setParentInputValue={handleSelectChange}
              endpoint_name={`search/officer_to_meet/${watch(
                "visiting_department"
              )}`}
              name={"officer_to_meet"}
              label={"Officer to Meet"}
              labelHint={false}
              id={"officer_to_meet"}
              placeholder={`${
                watch("visiting_department")
                  ? "Select Officer To Meet"
                  : "Select Visiting Department first"
              }`}
              isRequired={true}
            />
            {errors.officer_to_meet && (
              <p className="text-danger">{errors.officer_to_meet?.message}</p>
            )}
          </div>
        </div>
      </div>
      <h4 className="my-3">Vehicle's Informations</h4>
      <div className="row">
        <div className="form-group col-lg-4 col-md-6">
          <label className="form-label">Vehicle Available </label>
          <br />
          <div className="form-check custom-checkbox mb-2 form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              id="YESRadio"
              value="YES"
              {...register(`vehicle_available`, {
                required: true,
              })}
            />
            <label className="form-check-label" htmlFor="YESRadio">
              YES
            </label>
          </div>
          <div className="form-check custom-checkbox mb-2 form-check-inline">
            <input
              type="radio"
              className="form-check-input"
              id="NORadio"
              value="Female"
              {...register("vehicle_available", {
                required: true,
              })}
            />
            <label className="form-check-label" htmlFor="NORadio">
              NO
            </label>
          </div>
          {errors.vehicle_available && (
            <p className="text-danger">Select Vehicle Available</p>
          )}
        </div>
        {watch("vehicle_available") === "YES" && (
          <>
            <div className="form-group col-lg-4 col-md-6 mb-3">
              <label className="form-label">Vehicle Number</label>
              <input
                className="form-control"
                placeholder="Enter vehicle number"
                {...register(`vehicle_number`, {
                  required: true,
                })}
              />
              {errors.vehicle_number && (
                <p className="text-danger">Vehicle number is required</p>
              )}
            </div>

            <div className="form-group col-lg-4 col-md-6 mb-3">
              <label className="form-label">Driver DL Number</label>
              <input
                className="form-control"
                placeholder="Enter driver DL number"
                {...register(`driver_dl_number`, {
                  required: true,
                })}
              />
              {errors.driver_aadhaar_number && (
                <p className="text-danger">Driver DL number is required</p>
              )}
            </div>

            <div className="form-group col-lg-4 col-md-6 mb-3">
              <label className="form-label">Drive Type </label>
              <br />
              <div className="form-check custom-checkbox mb-2 form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  id="SelfRadio"
                  value="Self"
                  {...register(`drive_type`, {
                    required: true,
                  })}
                />
                <label className="form-check-label" htmlFor="SelfRadio">
                  Self
                </label>
              </div>
              <div className="form-check custom-checkbox mb-2 form-check-inline">
                <input
                  type="radio"
                  className="form-check-input"
                  id="OtherRadio"
                  value="Other"
                  {...register("drive_type", {
                    required: true,
                  })}
                />
                <label className="form-check-label" htmlFor="OtherRadio">
                  Other
                </label>
              </div>
              {errors.drive_type && (
                <p className="text-danger">Select Drive Type</p>
              )}
            </div>

            {watch("drive_type") === "Other" && (
              <>
                <div className="form-group col-lg-4 col-md-6 mb-3">
                  <label className="form-label">Driver Name</label>
                  <input
                    className="form-control"
                    placeholder="Enter driver name"
                    {...register(`driver_name`, {
                      required: true,
                    })}
                  />
                  {errors.driver_name && (
                    <p className="text-danger">Driver name is required</p>
                  )}
                </div>

                <div className="form-group col-lg-4 col-md-6 mb-3">
                  <label className="form-label">Driver Aadhaar Number</label>
                  <input
                    className="form-control"
                    placeholder="Enter driver aadhaar number"
                    {...register(`driver_aadhaar_number`, {
                      required: true,
                    })}
                  />
                  {errors.driver_aadhaar_number && (
                    <p className="text-danger">
                      Driver Aadhaar number is required
                    </p>
                  )}
                </div>

                <div className="form-group col-md-6 mb-3">
                  <label className="form-label">
                    Driver Profile Photo (JPG/PNG)
                  </label>
                  <div
                    className="file-upload-box"
                    onClick={() =>
                      document.getElementById("driverProfilePhotoInput").click()
                    }
                  >
                    {driverProfilePhotoPreviews ? (
                      <img
                        src={driverProfilePhotoPreviews}
                        alt="Profile Preview"
                      />
                    ) : (
                      <p>Click to upload</p>
                    )}
                  </div>
                  <input
                    id={"driverProfilePhotoInput"}
                    type="file"
                    className="form-control"
                    style={{ display: "none" }}
                    accept="image/jpeg, image/png"
                    {...register("driver_profile_photo", {
                      required: true,
                    })}
                    onChange={handleDriverProfilePhotoChange}
                  />
                  {errors.driver_profile_photo && (
                    <p className="text-danger">
                      Driver Profile Photo is required
                    </p>
                  )}
                </div>

                {/* driver aadhaar photo */}
                <div className="form-group col-md-6 mb-3">
                  <label className="form-label">
                    Driver Aadhaar Photo (JPG/PNG)
                  </label>
                  <div
                    className="file-upload-box"
                    onClick={() =>
                      document.getElementById("driverAadhaarPhotoInput").click()
                    }
                  >
                    {driverAadhaarPhotoPreviews ? (
                      <img
                        src={driverAadhaarPhotoPreviews}
                        alt="Aadhaar Preview"
                      />
                    ) : (
                      <p>Click to upload</p>
                    )}
                  </div>
                  <input
                    id={"driverAadhaarPhotoInput"}
                    type="file"
                    className="form-control"
                    style={{ display: "none" }}
                    accept="image/jpeg, image/png"
                    {...register("driver_aadhaar_photo", {
                      required: true,
                    })}
                    onChange={handleDriverAadhaarPhotoChange}
                  />
                  {errors.driver_aadhaar_photo && (
                    <p className="text-danger">
                      Driver aadhaar Photo is required
                    </p>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </div>

      <div className="d-flex align-items-center py-3">
        <h4 className="mb-0 me-2">Co-visitors</h4>
        {coVisitors.length < 3 && (
          <button
            type="button"
            className="btn btn-sm btn-outline-primary"
            onClick={() => appendCoVisitor({})}
          >
            Add Co-visitor
          </button>
        )}
      </div>

      {/* CoVisitors */}
      <div className="row">
        {coVisitors.map((coVisitor, index) => (
          <div key={index} className="col-md-4 col-sm-12 mb-2">
            <div className="border rounded">
              <div className="d-flex align-items-center justify-content-between border-bottom p-2">
                <h6 className="mb-0">Co-visitor {index + 1}</h6>
                <button
                  type="button"
                  className="btn btn-outline-danger btn-sm btn-circle"
                  onClick={() => {
                    const updatedProfilePrev = Object.fromEntries(
                      Object.entries(profilePhotoPreviews).filter(
                        ([key]) => key !== index.toString()
                      )
                    );
                    setProfilePhotoPreviews(updatedProfilePrev);

                    const updatedAadhaarPrev = Object.fromEntries(
                      Object.entries(aadhaarPhotoPreviews).filter(
                        ([key]) => key !== index.toString()
                      )
                    );
                    setAadhaarPhotoPreviews(updatedAadhaarPrev);
                    removeCoVisitor(index);
                  }}
                >
                  <i className="fa fa-solid fa-x"></i>
                </button>
              </div>
              <div className="px-2 pt-2">
                <div className="form-group mb-3">
                  <label className="form-label">First Name</label>
                  <input
                    className="form-control"
                    placeholder="Enter first name"
                    {...register(`coVisitors.${index}.first_name`)}
                  />
                  {errors.coVisitors?.[index]?.first_name && (
                    <p className="text-danger">
                      {errors.coVisitors[index].first_name?.message}
                    </p>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label className="form-label">Last Name</label>
                  <input
                    className="form-control"
                    placeholder="Enter last name"
                    {...register(`coVisitors.${index}.last_name`)}
                  />
                  {errors.coVisitors?.[index]?.last_name && (
                    <p className="text-danger">
                      {errors.coVisitors[index].last_name?.message}
                    </p>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label className="form-label">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter email"
                    {...register(`coVisitors.${index}.email`)}
                  />
                  {errors.coVisitors?.[index]?.email && (
                    <p className="text-danger">
                      {errors.coVisitors[index].email.message}
                    </p>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label className="form-label">Phone Number</label>
                  <input
                    className="form-control"
                    placeholder="Enter phone number"
                    {...register(`coVisitors.${index}.phone_number`)}
                  />
                  {errors.coVisitors?.[index]?.phone_number && (
                    <p className="text-danger">
                      {errors.coVisitors[index].phone_number.message}
                    </p>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label className="form-label">Company Name</label>
                  <input
                    className="form-control"
                    placeholder="Enter company name"
                    {...register(`coVisitors.${index}.company_name`)}
                  />
                  {errors.coVisitors?.[index]?.company_name && (
                    <p className="text-danger">
                      {errors.coVisitors[index].company_name.message}
                    </p>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label className="form-label">Designation</label>
                  <input
                    className="form-control"
                    placeholder="Enter designation"
                    {...register(`coVisitors.${index}.designation`)}
                  />
                  {errors.coVisitors?.[index]?.designation && (
                    <p className="text-danger">
                      {errors.coVisitors[index].designation.message}
                    </p>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label className="form-label">Aadhaar Number</label>
                  <input
                    className="form-control"
                    placeholder="Enter aadhaar number"
                    {...register(`coVisitors.${index}.aadhaar_number`)}
                  />
                  {errors.coVisitors?.[index]?.aadhaar_number && (
                    <p className="text-danger">
                      {errors.coVisitors[index].aadhaar_number.message}
                    </p>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label className="form-label">Date of Birth</label>
                  <input
                    type="date"
                    className="form-control"
                    {...register(`coVisitors.${index}.dob`)}
                  />
                  {errors.coVisitors?.[index]?.dob && (
                    <p className="text-danger">
                      {errors.coVisitors[index].dob.message}
                    </p>
                  )}
                </div>

                <div className="form-group col-md-6">
                  <label className="form-label">Gender </label>
                  <br />

                  <div className="form-check custom-checkbox mb-2 form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      id={`MaleRadio${index}`}
                      value="Male"
                      {...register(`coVisitors.${index}.gender`)}
                    />
                    <label className="form-label" htmlFor={`MaleRadio${index}`}>
                      Male
                    </label>
                  </div>
                  <div className="form-check custom-checkbox mb-2 form-check-inline">
                    <input
                      type="radio"
                      className="form-check-input"
                      id={`FemaleRadio${index}`}
                      value="Female"
                      {...register(`coVisitors.${index}.gender`)}
                    />
                    <label
                      className="form-label"
                      htmlFor={`FemaleRadio${index}`}
                    >
                      Female
                    </label>
                  </div>
                  {errors.coVisitors?.[index]?.gender && (
                    <p className="text-danger">
                      {errors.coVisitors[index].gender.message}
                    </p>
                  )}
                </div>

                {/* Profile Photo */}
                <div className="form-group col-md-6 mb-3">
                  <label className="form-label">Profile Photo (JPG/PNG)</label>
                  <div
                    className="file-upload-box"
                    onClick={() =>
                      document
                        .getElementById(`profilePhotoInput${index}`)
                        .click()
                    }
                  >
                    {profilePhotoPreviews[index] ? (
                      <img
                        src={profilePhotoPreviews[index]}
                        alt="Profile Preview"
                      />
                    ) : (
                      <p>Click to upload</p>
                    )}
                  </div>
                  <input
                    id={`profilePhotoInput${index}`}
                    type="file"
                    className="form-control"
                    style={{ display: "none" }}
                    accept="image/jpeg, image/png"
                    {...register(`coVisitors.${index}.profile_photo`)}
                    onChange={(e) => handleProfilePhotoChange(index, e)}
                  />
                  {errors.coVisitors?.[index]?.profile_photo && (
                    <p className="text-danger">
                      {errors.coVisitors[index].profile_photo.message}
                    </p>
                  )}
                </div>

                {/* aadhaar photo */}
                <div className="form-group col-md-6 mb-3">
                  <label className="form-label">Aadhaar Photo (JPG/PNG)</label>
                  <div
                    className="file-upload-box"
                    onClick={() =>
                      document
                        .getElementById(`aadhaarPhotoInput${index}`)
                        .click()
                    }
                  >
                    {aadhaarPhotoPreviews[index] ? (
                      <img
                        src={aadhaarPhotoPreviews[index]}
                        alt="Aadhaar Preview"
                      />
                    ) : (
                      <p>Click to upload</p>
                    )}
                  </div>
                  <input
                    id={`aadhaarPhotoInput${index}`}
                    type="file"
                    className="form-control"
                    style={{ display: "none" }}
                    accept="image/jpeg, image/png"
                    {...register(`coVisitors.${index}.aadhaar_photo`)}
                    onChange={(e) => handleAadhaarPhotoChange(index, e)}
                  />
                  {errors.coVisitors?.[index]?.aadhaar_photo && (
                    <p className="text-danger">
                      {errors.coVisitors[index].aadhaar_photo.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Returnable Items */}
      <div className="d-flex align-items-center py-3">
        <h4 className="mb-0 me-2">Returnable Items</h4>
        <button
          type="button"
          className="btn btn-sm btn-outline-primary"
          onClick={() => appendReturnableItem({})}
        >
          Add Item
        </button>
      </div>

      <div className="row">
        {returnableItems.map((item, index) => (
          <div key={item.id} className="col-md-6 col-sm-12 mb-2">
            <div className="border rounded">
              <div className="d-flex align-items-center justify-content-between border-bottom p-2">
                <h6 className="mb-0">Item {index + 1}</h6>
                <button
                  type="button"
                  className="btn btn-outline-danger btn-sm btn-circle"
                  onClick={() => {
                    const updatedItemPrev = Object.fromEntries(
                      Object.entries(itemPhotoPreviews).filter(
                        ([key]) => key !== index.toString()
                      )
                    );
                    setItemPhotoPreviews(updatedItemPrev);
                    removeReturnableItem(index);
                  }}
                >
                  <i className="fa fa-solid fa-x"></i>
                </button>
              </div>
              <div className="row px-2 py-2">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group  mb-3">
                    <label className="form-label">Item Name</label>
                    <input
                      className="form-control"
                      placeholder="Enter item name"
                      {...register(`returnableItems.${index}.item_name`)}
                    />
                    {errors.returnableItems?.[index]?.item_name && (
                      <p className="text-danger">
                        {errors.returnableItems[index].item_name.message}
                      </p>
                    )}
                  </div>

                  <div className="form-group  mb-3">
                    <label className="form-label">Item Description</label>
                    <textarea
                      placeholder="Enter Item details"
                      className="form-control"
                      {...register(`returnableItems.${index}.item_description`)}
                    ></textarea>
                    {errors.returnableItems?.[index]?.item_description && (
                      <p className="text-danger">
                        {errors.returnableItems[index].item_description.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 mb-3">
                  <label className="form-label">Item Photo (JPG/PNG)</label>
                  <div
                    className="file-upload-box"
                    onClick={() =>
                      document.getElementById(`itemPhotoInput${index}`).click()
                    }
                  >
                    {itemPhotoPreviews[index] ? (
                      <img src={itemPhotoPreviews[index]} alt="Item Preview" />
                    ) : (
                      <p>Click to upload</p>
                    )}
                  </div>
                  <input
                    id={`itemPhotoInput${index}`}
                    type="file"
                    className="form-control"
                    style={{ display: "none" }}
                    accept="image/jpeg, image/png"
                    {...register(`returnableItems.${index}.item_photo`)}
                    onChange={(e) => handleItemPhotoChange(index, e)}
                  />
                  {errors.returnableItems?.[index]?.item_photo && (
                    <p className="text-danger">
                      {errors.returnableItems[index].item_photo.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="form-group my-3">
        <label className="form-label">Purpose of Visiting</label>
        <textarea
          className="form-control"
          placeholder="Enter purpose of visiting"
          {...register("purpose_of_visiting")}
        />
        {errors.purpose_of_visiting && (
          <p className="text-danger">{errors.purpose_of_visiting?.message}</p>
        )}
      </div>
      <button type="submit" disabled={loading} className="btn btn-primary ">
        {loading ? (
          <>
            <Spinner size="sm" /> Submiting... Gatepass
          </>
        ) : (
          "Submit Gatepass"
        )}
      </button>
    </form>
  );
};

export default GatepassForm;
