import React from "react";
import bg6 from '../../images/background/home_bg.png';
import logo from '../../images/logo/logo.png';
import logoText from '../../images/logo/logo_text.png';
import VisitorSignupForm from "./RegistrationForm";
import { Link } from "react-router-dom";

function Register() {

	return (
		<>
			<div className="page-wraper">
				<div className="browse-job login-style3">
					<div className="bg-img-fix overflow-hidden" style={{ background: '#fff url(' + bg6 + ')', height: "100vh" }}>
						<div className="row gx-0">

							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 vh-100 bg-white d-flex align-items-center justify-content-center">
								<div className="login-form style-2 flex-fill">
									<div className="pt-4">
										<Link to={"/"}>
										<div style={{ marginBottom: '.5rem', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
											<img src={logo} alt="" width={'50px'} className="width-200 " style={{ marginRight: '0.6rem' }} />
											<img src={logoText} alt="" width={'150px'} className="width-200 light-logo" />
										</div>
										</Link>
									</div>
									<VisitorSignupForm />
									<div className="card-footer">
										<div className=" bottom-footer clearfix m-t10 m-b20 row text-center">
											<div className="col-lg-12 text-center">
												<span> © Copyright by <a href="https://intelisparkz.com/" rel="noreferrer" target="_blank"> InteliSparkz </a> All rights reserved.</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};


export default Register;

