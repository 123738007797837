import React, { useState } from "react";
import OtpInput from "./OTPInput"; // Import the OTP input component
import { verifySignupOtpApi } from "../../services/AuthService";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const VisitorSignupOtpForm = ({data, toggleOtpVisible, handleClear}) => {
  console.log("user data", data)
  const [otp, setOtp] = useState({ email_otp: "", phone_otp: "" });
  const [loading, setLoading] = useState(false)
  const onSubmitOtp = (name, code) => {
    setOtp({ ...otp, [name]: code })
  };
  const navigate = useNavigate()

  const handleOnVerify = async () => {
    if(loading){
      return false
    }
    try {
      setLoading(true)
      const resp = await verifySignupOtpApi({...otp, id:data.id})
      const message = resp.data.message
      toast.success(message)
      setLoading(false)
      handleClear()
      navigate('/')
      toggleOtpVisible()
    } catch (error) {
      if(error.response){
        toast.error(error.response.data.message);
      }
      setLoading(false)
    }
  }

  return (
    <div className="d-flex align-items-center flex-column mt-4">
      <div className="d-flex flex-column">
        <h5 className="mb-0">Enter OTP for Email:</h5>
        <OtpInput name={"email_otp"} length={6} onSubmitOtp={onSubmitOtp} />
        <div className="d-flex justify-content-end my-2">
          <span className="">Resend Email OTP</span>
        </div>
      </div>

      <div className="d-flex flex-column mt-3">
        <h5 className="mb-0">Enter OTP for Phone:</h5>
        <OtpInput name={"phone_otp"} length={6} onSubmitOtp={onSubmitOtp} />
        <div className="d-flex justify-content-end my-2">
          <span >Resend Phone OTP</span>
        </div>


        <button type="button" disabled={loading} onClick={handleOnVerify} className="btn btn-primary btn-block mt-2">
          {loading ? <><Spinner size="sm" /> Verifing...</> : "Verify"}
        </button>

      </div>
    </div>
  );
};

export default VisitorSignupOtpForm;
