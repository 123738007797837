import React, { useState, useEffect, useCallback } from "react";
import { getDepartmentListApi, getDesignationListApi } from "../../../services/OtherService";
import { CancelRequest } from "../../../services/AxiosInstance";
import Pagination from "../../constant/Pagination";
import DepartmentAddModal from "./AddDepartmentModal";
import DepartmentEditModal from "./EditDepartmentModal";
import DesignationAddModal from "./AddDesignationModal";
import DesignationEditModal from "./EditDesignationModal";
import debounce from 'lodash.debounce';
import TableLoaderEffect from '../../constant/TableLoader';

const DepartmentPage = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const recordsPage = 10;

    const fetchData = useCallback((page, query) => {
        setIsLoading(true);
        getDepartmentListApi(page, recordsPage, query)
            .then((resp) => {
                setData(resp.data.data);
                setTotal(resp.data.total);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error.response?.data?.message || "Error fetching data");
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchData(currentPage, searchQuery);
    }, [currentPage, searchQuery, fetchData]);

    const handlePagination = (page) => {
        setCurrentPage(page.selected + 1);
    };

    const handleSearchChange = debounce((value) => {
        if (value.length > 0) CancelRequest();
        setSearchQuery(value);
    }, 300);

    const handleAfterCreate = (newData) => {
        setData((prevData) => [newData, ...prevData]);
        setTotal((prevTotal) => prevTotal + 1);
    };

    const handleAfterUpdate = (updatedData) => {
        setData((prevData) =>
            prevData.map((item) =>
                item.id === updatedData.id ? { ...item, ...updatedData } : item
            )
        );
    };

    return (
        <div className="card">
            <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                    <div className="d-flex justify-content-between text-wrap align-items-center p-3">
                        <h4 className="heading mb-0">Department List</h4>
                        <div className='d-flex'>
                            <div className='form-group me-3'>
                                <input type="search" name="serach" className="form-control "
                                    placeholder="Search"
                                    value={searchQuery} onChange={handleSearchChange} />
                            </div>
                            <DepartmentAddModal handleAfterCreate={handleAfterCreate} />
                        </div>
                    </div>

                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? <TableLoaderEffect colLength={2} /> : data.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.name}</td>
                                        <td>
                                            <DepartmentEditModal data={item} handleAfterUpdate={handleAfterUpdate} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {isLoading ? <div style={{ height: '4rem' }}></div> : <Pagination
                            currentPage={currentPage}
                            recordsPage={recordsPage}
                            dataLength={total}
                            handlePagination={handlePagination}
                        />}
                    </div>
                </div>
            </div>
        </div>
    );
};



const DesignationPage = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const recordsPage = 10;

    const fetchData = useCallback((page, query) => {
        setIsLoading(true);
        getDesignationListApi(page, recordsPage, query)
            .then((resp) => {
                setData(resp.data.data);
                setTotal(resp.data.total);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error.response?.data?.message || "Error fetching data");
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchData(currentPage, searchQuery);
    }, [currentPage, searchQuery, fetchData]);

    const handlePagination = (page) => {
        setCurrentPage(page.selected + 1);
    };

    const handleSearchChange = debounce((value) => {
        if (value.length > 0) CancelRequest();
        setSearchQuery(value);
    }, 300);

    const handleAfterCreate = (newData) => {
        setData((prevData) => [newData, ...prevData]);
        setTotal((prevTotal) => prevTotal + 1);
    };

    const handleAfterUpdate = (updatedData) => {
        setData((prevData) =>
            prevData.map((item) =>
                item.id === updatedData.id ? { ...item, ...updatedData } : item
            )
        );
    };

    return (
        <div className="card">
            <div className="card-body p-0">
                <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                    <div className="d-flex justify-content-between text-wrap align-items-center p-3">
                        <h4 className="heading mb-0">Designation List</h4>
                        <div className='d-flex'>
                            <div className='form-group me-3'>
                                <input type="search" name="serach" className="form-control "
                                    placeholder="Search"
                                    value={searchQuery} onChange={handleSearchChange} />
                            </div>
                            <DesignationAddModal handleAfterCreate={handleAfterCreate} />
                        </div>
                    </div>

                    <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                        <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? <TableLoaderEffect colLength={2} /> : data.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            {item.name}
                                        </td>
                                        <td>
                                            <DesignationEditModal data={item} handleAfterUpdate={handleAfterUpdate} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        {isLoading ? <div style={{ height: '4rem' }}></div> : <Pagination
                            currentPage={currentPage}
                            recordsPage={recordsPage}
                            dataLength={total}
                            handlePagination={handlePagination}
                        />}
                    </div>
                </div>
            </div>
        </div>
    );
};


const DepartmentDesignationPage = () => {
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-6">
                    <DepartmentPage />
                </div>
                <div className="col-xl-6">
                    <DesignationPage />
                </div>
            </div>
        </div>
    )
}


export default DepartmentDesignationPage;