import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Placeholder } from "react-bootstrap";
import { getGatepassApi } from "../../../services/GatepassService";
import ApproveModal from "./ApproveModal";

const GatepassDetailsLoading = () => {
  return (
    <div>
      <div className="card">
        <div className="card-body">
          <div className="w-100 px-2 mt-3">
            <Placeholder animation="glow">
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
              <Placeholder
                xs={12}
                style={{ height: "20px" }}
                className="rounded my-2"
              />
            </Placeholder>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-item-center justify-content-between">
            <div className="w-100 px-2">
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
              </Placeholder>
            </div>

            <div className="w-100 px-2">
              <Placeholder animation="glow">
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
                <Placeholder
                  xs={12}
                  style={{ height: "20px" }}
                  className="rounded my-2"
                />
              </Placeholder>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function GatepassDetailsPage() {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeVisitor, setActiveVisitor] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await getGatepassApi(id);
      const resp_data = resp.data.data;
      setData(resp_data);

    } catch (error) {
      console.error("Error fetching user:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [id]);

  const updateData = (updateData) => {
    setData({...data, ...updateData});
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    // Adjust age if the birth date has not yet occurred this year
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }

    return age;
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-4">
            {isLoading ? (
              <GatepassDetailsLoading />
            ) : data !== null ? (
              <div>
                <div className="card">
                  <div className="card-header">
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="d-flex  align-items-center">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-sm btn-circle"
                          onClick={() => navigate("/gatepass")}
                        >
                          <i className="fa-solid fa-arrow-left"></i>
                        </button>
                        <h4 className="mb-0 ms-2">Gatepass Details</h4>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {data ? (
                      <>
                        <div>
                          <ul>
                            <li className="mb-3 mt-1">
                              <span className="fw-bold">Gatepass Number: </span>
                              {data?.gatepass_number}
                            </li>

                            <li className="my-3">
                              <span className="fw-bold">Start Date: </span>
                              {data?.visiting_start_date}
                            </li>
                            <li className="my-3">
                              <span className="fw-bold">End Date: </span>
                              {data?.visiting_end_date}
                            </li>

                            <li className="my-3">
                              <span className="fw-bold">Status: </span>
                              {data?.status === "Approved" ? (
                                <span className="badge light border-0 badge-success badge-sm ms-2">Approved</span>
                              ) : data?.status === "Pending" ? (
                                <ApproveModal id={data?.id} officerId={data?.officer_to_meet?.id} setData={setData} updateData={updateData} />
                               ) : (
                                <span className="badge light border-0 badge-danger badge-sm ms-2">
                                  Rejected
                                </span>
                              )}
                            </li>
                            <li className="my-3">
                              <span className="fw-bold">Department: </span>
                              {data?.visiting_department?.name}
                            </li>
                          </ul>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h5 className="">Officers To Meet</h5>
                    <div className="d-flex align-items-start justify-content-center flex-column border-top">
                      <ul>
                        <li className="my-3">
                          <span className="fw-bold">Name: </span>
                          {data?.officer_to_meet?.first_name}
                          {data?.officer_to_meet?.last_name}
                        </li>

                        <li className="my-3">
                          <span className="fw-bold">Email Id: </span>
                          {data?.officer_to_meet?.email}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {data?.vehicle && (
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between my-1 py-2">
                        <h5 className="mb-md-0">Vehicle's Information</h5>
                      </div>
                      <div className="d-flex align-items-center justify-content-between border-top my-1 py-2">
                        <ul>
                          <li className="mt-1">
                            <span className="fw-bold">Vehicle Number: </span>
                            {data?.vehicle?.vehicle_number}
                          </li>
                          <li className="my-2">
                            <span className="fw-bold">Driver Name: </span>
                            {data?.vehicle?.driver_name}
                          </li>
                          <li className="my-2">
                            <span className="fw-bold">Driving Licenece: </span>
                            {data?.vehicle?.driving_licence_number}
                          </li>
                          <li className="my-2">
                            <span className="fw-bold">Self Driving: </span>
                            <span
                              className={`badge light border-0 ${
                                data?.vehicle?.is_self_drive
                                  ? "badge-success"
                                  : "badge-danger"
                              } badge-sm ms-2`}
                            >
                              {data?.vehicle?.is_self_drive ? "Yes" : "No"}
                            </span>
                          </li>
                          <li className="my-2">
                            <span className="fw-bold">Aadhaar Number: </span>
                            {data?.vehicle?.aadhaar_number}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="card">
                <div className="card-body">
                  <h4 className="text-center text-danger">
                    ⚠️ User Not Found!
                    <br /> ID {id}
                  </h4>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-8">
            <div className="content">
              <div className="card">
                <div className="card-header">
                  <h4 className="mb-0 ms-2">Co-Visitors Details</h4>
                </div>
                <div className="card-body px-4">
                  <div className="row">
                    {data?.covisitors.map((covisit, index) => (
                      <div className="col-md-6 mb-4" key={covisit.id}>
                        <div
                          className={`card covisit-card border-0 shadow-sm ${
                            activeVisitor === index
                              ? "active-card"
                              : "inactive-card"
                          }`}
                        >
                          <div className="card-body covisit-card-body p-1">
                            <div className="row">
                              <div className="col-5 px-1">
                                <img
                                  src={`${process.env.REACT_APP_BASE_URL}${covisit?.profile_photo}`}
                                  alt="User Image"
                                  className="img-fluid rounded"
                                />
                                {activeVisitor === index && (
                                  <img
                                    src={`${process.env.REACT_APP_BASE_URL}${covisit?.aadhaar_photo}`}
                                    alt="Aadhaar Image"
                                    className="img-fluid mt-2 rounded"
                                  />
                                )}
                              </div>

                              <div className="col-7 p-3">
                                <h5 className="mt-0 mb-2 text-dark">
                                  {covisit?.first_name} {covisit?.last_name}
                                </h5>
                                <ul className="list-unstyled text-smoke mb-2">
                                  <li className="d-flex mb-2">
                                    <i className="mdi mdi-email me-2"></i>
                                    <span>{covisit?.email}</span>
                                  </li>
                                  <li className="d-flex mb-2">
                                    <i className="mdi mdi-phone me-2"></i>
                                    <span>{covisit?.phone_number}</span>
                                  </li>
                                </ul>
                                <span
                                  className="text-primary cursor-pointer"
                                  onClick={() =>
                                    setActiveVisitor(
                                      activeVisitor === index ? null : index
                                    )
                                  }
                                >
                                  {activeVisitor === index
                                    ? "Hide Details"
                                    : "View Details"}{" "}
                                  <i className="fa-solid fa-angle-right"></i>
                                </span>

                                {/* Show additional details if this is the active visitor */}
                                {activeVisitor === index && (
                                  <div className="mt-3">
                                    <p className="mb-2">
                                      <strong>Date of Birth:</strong>{" "}
                                      {covisit?.dob}{" "}
                                      <strong>
                                        ({calculateAge(covisit.dob)} yr)
                                      </strong>
                                    </p>
                                    <p className="mb-2">
                                      <strong>Gender:</strong> {covisit?.gender}
                                    </p>
                                    <p className="mb-2">
                                      <strong>Designation:</strong>{" "}
                                      {covisit?.designation}
                                    </p>
                                    <p className="mb-2">
                                      <strong>Company:</strong>{" "}
                                      {covisit?.company_name}
                                    </p>
                                    <p>
                                      <strong>Aadhaar:</strong>{" "}
                                      {covisit?.aadhaar_number}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h4 className="mb-0 ms-2">Returnable Items</h4>
                </div>
                <div className="card-body px-1">
                  <div className="row">
                    {data?.returnble_items.map((item, index) => (
                      <div className="col-md-6 mb-4" key={item.id}>
                        <div class="covisit-card card p-2">
                          <img
                            src={`${process.env.REACT_APP_BASE_URL}${item?.item_photo}`}
                            alt="Item Image"
                            className="img-fluid rounded"
                          />
                          <div class="card-body text-left">
                            <h5 class="card-title">{item?.item_name}</h5>
                            <p class="card-text">{item?.description}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GatepassDetailsPage;
