import GatepassForm from "./GatePassForm";
const AddgatepassPage = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h3 className="">Gatepass Form</h3>
              <GatepassForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddgatepassPage;
