import React, { useState, useEffect, useCallback } from "react";
import { getGatepassListApi } from "../../../services/GatepassService";
import { CancelRequest } from "../../../services/AxiosInstance";
import { useNavigate } from "react-router-dom";
import Pagination from "../../constant/Pagination";
import debounce from 'lodash.debounce';
import TableLoaderEffect from '../../constant/TableLoader';
import { connect } from "react-redux";

const GatepassPage = ({useRole}) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const recordsPage = 10;

    const navigate = useNavigate();

    const fetchData = useCallback((page, query) => {
        setIsLoading(true);
        getGatepassListApi(page, recordsPage, query)
            .then((resp) => {
                setData(resp.data.data);
                setTotal(resp.data.total);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error.response?.data?.message || "Error fetching data");
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchData(currentPage, searchQuery);
    }, [currentPage, searchQuery, fetchData]);

    const handlePagination = (page) => {
        setCurrentPage(page.selected + 1);
    };

    const handleSearchChange = debounce((value) => {
        if (value.length > 0) CancelRequest();
        setSearchQuery(value);
    }, 300);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-body p-0">
                            <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                <div className="d-flex justify-content-between text-wrap align-items-center p-3">
                                    <h4 className="heading mb-0">Gatepass List</h4>
                                    <div className='d-flex'>
                                        <div className='form-group me-3'>
                                            <input type="search" name="serach" className="form-control "
                                                placeholder="Search"
                                                value={searchQuery} onChange={handleSearchChange} />
                                        </div>
                                        {useRole === "Visitor" && (
                                            <button className="btn btn-sm btn-primary" onClick={()=> navigate('/apply-gatepass')}>Apply Gatepass</button>
                                        )}
                                    </div>
                                </div>

                                <div id="employee-tbl_wrapper" className="dataTables_wrapper no-footer">
                                    <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0">
                                        <thead>
                                            <tr>
                                                <th>Gatepass Number</th>
                                                <th>Start Date</th>
                                                <th>End Date</th>
                                                <th>Officer To Meet </th>
                                                <th>Department</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading ? <TableLoaderEffect colLength={9} /> : data.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <span className="text-primary fw-bold c-pointer"
                                                            onClick={() => navigate(`/gatepass/${item.id}`)}>{item.gatepass_number}</span>
                                                    </td>
                                                    <td>{item.visiting_start_date}</td>
                                                    <td>{item.visiting_end_date}</td>
                                                    <td>{item.officer_to_meet}</td>
                                                    <td>{item.visiting_department}</td>
                                                    <td>{item.status === "Approved" ? <span className='badge light border-0 badge-success badge-sm'>Approve</span> :
                                                        item.status === "Pending" ? <span className='badge light border-0 badge-warning badge-sm'>Pending</span> : <span className='badge light border-0 badge-danger badge-sm'>Rejected</span>}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    {isLoading ? <div style={{ height: '4rem' }}></div> : <Pagination
                                        currentPage={currentPage}
                                        recordsPage={recordsPage}
                                        dataLength={total}
                                        handlePagination={handlePagination}
                                    />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
const mapStateToProps = (state) => ({
    useRole: state.auth.user?.role || "Visitor",
});

export default connect(mapStateToProps)(GatepassPage);



//
//

// import React, { useState } from "react";
// import { useForm, useFieldArray } from "react-hook-form";
// import DynamicSelectInput from "../../constant/SelectInput";

// const GatepassForm = () => {
  
//   const {
//     register,
//     control,
//     handleSubmit,
//     watch,
//     setValue,
//     clearErrors,
//     formState: { errors },
//   } = useForm({
//     defaultValues: {
//       coVisitors: [],
//       returnableItems: [],
//     },
//   });

//   const {
//     fields: coVisitors,
//     append: appendCoVisitor,
//     remove: removeCoVisitor,
//   } = useFieldArray({
//     control,
//     name: "coVisitors",
//   });

//   const {
//     fields: returnableItems,
//     append: appendReturnableItem,
//     remove: removeReturnableItem,
//   } = useFieldArray({
//     control,
//     name: "returnableItems",
//   });

//   const [profilePhotoPreviews, setProfilePhotoPreviews] = useState({});
//   const [aadhaarPhotoPreviews, setAadhaarPhotoPreviews] = useState({});
//   const [driverProfilePhotoPreviews, setDriverProfilePhotoPreviews] =
//     useState("");
//   const [driverAadhaarPhotoPreviews, setDriverAadhaarPhotoPreviews] =
//     useState("");
//   const [itemPhotoPreviews, setItemPhotoPreviews] = useState({});

//   const handleProfilePhotoChange = (index, e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setProfilePhotoPreviews((prev) => ({
//           ...prev,
//           [index]: reader.result,
//         }));
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleAadhaarPhotoChange = (index, e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setAadhaarPhotoPreviews((prev) => ({
//           ...prev,
//           [index]: reader.result,
//         }));
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleDriverProfilePhotoChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setDriverProfilePhotoPreviews(reader.result);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleDriverAadhaarPhotoChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setDriverAadhaarPhotoPreviews(reader.result);
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleItemPhotoChange = (index, e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setItemPhotoPreviews((prev) => ({
//           ...prev,
//           [index]: reader.result,
//         }));
//       };
//       reader.readAsDataURL(file);
//     }
//   };

//   const handleSelectChange = (name, value, _) => {
//     setValue(name, value);
//     clearErrors(name);
//   };

//   const onSubmit = (data) => {
//     console.log(data);
//   };

//   return (
//     <form onSubmit={handleSubmit(onSubmit)}>
//       {/* Gatepass Information */}
//       <h4 className="mb-3">Visiting Details</h4>
//       <div className="row">
//         <div className="col-md-6">
//           <div className="form-group">
//             <label>Visiting Start Date</label>
//             <input
//               type="date"
//               className="form-control"
//               {...register("visiting_start_date", {
//                 required: "Start date is required",
//               })}
//             />
//             <p className="text-danger">{errors.visiting_start_date?.message}</p>
//           </div>

//           <div className="form-group">
//             <label>Visiting End Date</label>
//             <input
//               type="date"
//               className="form-control"
//               {...register("visiting_end_date", {
//                 required: "End date is required",
//               })}
//             />
//             <p className="text-danger">{errors.visiting_end_date?.message}</p>
//           </div>
//         </div>

//         <div className="col-md-6">
//           <div className="form-group">
//             <DynamicSelectInput
//               parentClassName="mb-0"
//               setParentInputValue={handleSelectChange}
//               endpoint_name="search/department"
//               name={"visiting_department"}
//               label={"Visiting Department"}
//               labelHint={false}
//               id={"visiting_department"}
//               placeholder={"Select Visiting Department"}
//               isRequired={true}
//             />
//             <p className="text-danger">{errors.visiting_department?.message}</p>
//           </div>

//           <div className="form-group">
//             <DynamicSelectInput
//               parentClassName="mb-0"
//               setParentInputValue={handleSelectChange}
//               endpoint_name="search/designation"
//               name={"officer_to_meet"}
//               label={"Officer to Meet"}
//               labelHint={false}
//               id={"officer_to_meet"}
//               placeholder={"Select Designation"}
//               isRequired={true}
//             />
//             <p className="text-danger">{errors.officer_to_meet?.message}</p>
//           </div>
//         </div>
//       </div>
//       <h4 className="mb-3">Vehicle Details</h4>
//       <div className="row">
//         <div className="form-group col-lg-4 col-md-6">
//           <label className="form-label">Vehicle Available </label>
//           <br />
//           <div className="form-check custom-checkbox mb-2 form-check-inline">
//             <input
//               className="form-check-input"
//               type="radio"
//               id="YESRadio"
//               value="YES"
//               {...register(`vehicle_available`, {
//                 required: true,
//               })}
//             />
//             <label className="form-check-label" htmlFor="YESRadio">
//               YES
//             </label>
//           </div>
//           <div className="form-check custom-checkbox mb-2 form-check-inline">
//             <input
//               type="radio"
//               className="form-check-input"
//               id="NORadio"
//               value="Female"
//               {...register("vehicle_available", {
//                 required: true,
//               })}
//             />
//             <label className="form-check-label" htmlFor="NORadio">
//               NO
//             </label>
//           </div>
//           {errors.vehicle_available && (
//             <p className="text-danger">Select Vehicle Available</p>
//           )}
//         </div>
//         {watch("vehicle_available") === "YES" && (
//           <>
//             <div className="form-group col-lg-4 col-md-6 mb-3">
//               <label>Vehicle Number</label>
//               <input
//                 className="form-control"
//                 placeholder="Enter vehicle number"
//                 {...register(`vehicle_number`, {
//                   required: true,
//                 })}
//               />
//               {errors.vehicle_number && (
//                 <p className="text-danger">Vehicle number is required</p>
//               )}
//             </div>

//             <div className="form-group col-lg-4 col-md-6 mb-3">
//               <label>Driver DL Number</label>
//               <input
//                 className="form-control"
//                 placeholder="Enter driver DL number"
//                 {...register(`driver_dl_number`, {
//                   required: true,
//                 })}
//               />
//               {errors.driver_aadhaar_number && (
//                 <p className="text-danger">Driver DL number is required</p>
//               )}
//             </div>

//             <div className="form-group col-lg-4 col-md-6 mb-3">
//               <label className="form-label">Drive Type </label>
//               <br />
//               <div className="form-check custom-checkbox mb-2 form-check-inline">
//                 <input
//                   className="form-check-input"
//                   type="radio"
//                   id="SelfRadio"
//                   value="Self"
//                   {...register(`drive_type`, {
//                     required: true,
//                   })}
//                 />
//                 <label className="form-check-label" htmlFor="SelfRadio">
//                   Self
//                 </label>
//               </div>
//               <div className="form-check custom-checkbox mb-2 form-check-inline">
//                 <input
//                   type="radio"
//                   className="form-check-input"
//                   id="OtherRadio"
//                   value="Other"
//                   {...register("drive_type", {
//                     required: true,
//                   })}
//                 />
//                 <label className="form-check-label" htmlFor="OtherRadio">
//                   Other
//                 </label>
//               </div>
//               {errors.drive_type && (
//                 <p className="text-danger">Select Drive Type</p>
//               )}
//             </div>

//             {watch("drive_type") === "Other" && (
//               <>
//                 <div className="form-group col-lg-4 col-md-6 mb-3">
//                   <label>Driver Name</label>
//                   <input
//                     className="form-control"
//                     placeholder="Enter driver name"
//                     {...register(`driver_name`, {
//                       required: true,
//                     })}
//                   />
//                   {errors.driver_name && (
//                     <p className="text-danger">Driver name is required</p>
//                   )}
//                 </div>

//                 <div className="form-group col-lg-4 col-md-6 mb-3">
//                   <label>Driver Aadhaar Number</label>
//                   <input
//                     className="form-control"
//                     placeholder="Enter driver aadhaar number"
//                     {...register(`driver_aadhaar_number`, {
//                       required: true,
//                     })}
//                   />
//                   {errors.driver_aadhaar_number && (
//                     <p className="text-danger">
//                       Driver Aadhaar number is required
//                     </p>
//                   )}
//                 </div>

//                 <div className="form-group col-md-6 mb-3">
//                   <label className="form-label">
//                     Driver Profile Photo (JPG/PNG)
//                   </label>
//                   <div
//                     className="file-upload-box"
//                     onClick={() =>
//                       document.getElementById("driverProfilePhotoInput").click()
//                     }
//                   >
//                     {driverProfilePhotoPreviews ? (
//                       <img
//                         src={driverProfilePhotoPreviews}
//                         alt="Profile Preview"
//                       />
//                     ) : (
//                       <p>Click to upload</p>
//                     )}
//                   </div>
//                   <input
//                     id={"driverProfilePhotoInput"}
//                     type="file"
//                     className="form-control"
//                     style={{ display: "none" }}
//                     accept="image/jpeg, image/png"
//                     {...register("driver_profile_photo", {
//                       required: true,
//                     })}
//                     onChange={handleDriverProfilePhotoChange}
//                   />
//                   {errors.driver_profile_photo && (
//                     <p className="text-danger">
//                       Driver Profile Photo is required
//                     </p>
//                   )}
//                 </div>

//                 {/* driver aadhaar photo */}
//                 <div className="form-group col-md-6 mb-3">
//                   <label className="form-label">
//                     Driver Aadhaar Photo (JPG/PNG)
//                   </label>
//                   <div
//                     className="file-upload-box"
//                     onClick={() =>
//                       document.getElementById("driverAadhaarPhotoInput").click()
//                     }
//                   >
//                     {driverAadhaarPhotoPreviews ? (
//                       <img
//                         src={driverAadhaarPhotoPreviews}
//                         alt="Aadhaar Preview"
//                       />
//                     ) : (
//                       <p>Click to upload</p>
//                     )}
//                   </div>
//                   <input
//                     id={"driverAadhaarPhotoInput"}
//                     type="file"
//                     className="form-control"
//                     style={{ display: "none" }}
//                     accept="image/jpeg, image/png"
//                     {...register("driver_aadhaar_photo", {
//                       required: true,
//                     })}
//                     onChange={handleDriverAadhaarPhotoChange}
//                   />
//                   {errors.driver_aadhaar_photo && (
//                     <p className="text-danger">
//                       Driver aadhaar Photo is required
//                     </p>
//                   )}
//                 </div>
//               </>
//             )}
//           </>
//         )}
//       </div>

//       <div className="d-flex align-items-center py-3">
//         <h4 className="mb-0 me-2">Co-visitors</h4>
//         {coVisitors.length < 3 && (
//           <button
//             type="button"
//             className="btn btn-sm btn-outline-primary"
//             onClick={() => appendCoVisitor({})}
//           >
//             Add Co-visitor
//           </button>
//         )}
//       </div>

//       {/* CoVisitors */}
//       <div className="row">
//         {coVisitors.map((coVisitor, index) => (
//           <div key={coVisitor.id} className="col-md-4 col-sm-12 mb-2">
//             <div className="border rounded">
//               <div className="d-flex align-items-center justify-content-between border-bottom p-2">
//                 <h6 className="mb-0">Co-visitor {index + 1}</h6>
//                 <button
//                   type="button"
//                   className="btn btn-outline-danger btn-sm btn-circle"
//                   onClick={() => removeCoVisitor(index)}
//                 >
//                   <i className="fa fa-solid fa-x"></i>
//                 </button>
//               </div>
//               <div className="px-2 pt-2">
//                 <div className="form-group mb-3">
//                   <label>First Name</label>
//                   <input
//                     className="form-control"
//                     placeholder="Enter first name"
//                     {...register(`coVisitors.${index}.first_name`, {
//                       required: true,
//                     })}
//                   />
//                   {errors.coVisitors?.[index]?.first_name && (
//                     <p className="text-danger">First name is required</p>
//                   )}
//                 </div>

//                 <div className="form-group mb-3">
//                   <label>Last Name</label>
//                   <input
//                     className="form-control"
//                     placeholder="Enter last name"
//                     {...register(`coVisitors.${index}.last_name`, {
//                       required: true,
//                     })}
//                   />
//                   {errors.coVisitors?.[index]?.last_name && (
//                     <p className="text-danger">Last name is required</p>
//                   )}
//                 </div>

//                 <div className="form-group mb-3">
//                   <label>Email</label>
//                   <input
//                     type="email"
//                     className="form-control"
//                     placeholder="Enter email"
//                     {...register(`coVisitors.${index}.email`, {
//                       required: true,
//                     })}
//                   />
//                   {errors.coVisitors?.[index]?.email && (
//                     <p className="text-danger">Email is required</p>
//                   )}
//                 </div>

//                 <div className="form-group mb-3">
//                   <label>Phone Number</label>
//                   <input
//                     className="form-control"
//                     placeholder="Enter phone number"
//                     {...register(`coVisitors.${index}.phone_number`, {
//                       required: true,
//                     })}
//                   />
//                   {errors.coVisitors?.[index]?.phone_number && (
//                     <p className="text-danger">Phone number is required</p>
//                   )}
//                 </div>

//                 <div className="form-group mb-3">
//                   <label>Company Name</label>
//                   <input
//                     className="form-control"
//                     placeholder="Enter company name"
//                     {...register(`coVisitors.${index}.company_name`, {
//                       required: true,
//                     })}
//                   />
//                   {errors.coVisitors?.[index]?.company_name && (
//                     <p className="text-danger">Company is required</p>
//                   )}
//                 </div>

//                 <div className="form-group mb-3">
//                   <label>Designation</label>
//                   <input
//                     className="form-control"
//                     placeholder="Enter designation"
//                     {...register(`coVisitors.${index}.designation`, {
//                       required: true,
//                     })}
//                   />
//                   {errors.coVisitors?.[index]?.designation && (
//                     <p className="text-danger">Designation is required</p>
//                   )}
//                 </div>

//                 <div className="form-group mb-3">
//                   <label>Aadhaar Number</label>
//                   <input
//                     className="form-control"
//                     placeholder="Enter aadhaar number"
//                     {...register(`coVisitors.${index}.aadhaar_number`, {
//                       required: true,
//                     })}
//                   />
//                   {errors.coVisitors?.[index]?.aadhaar_number && (
//                     <p className="text-danger">Aadhaar number is required</p>
//                   )}
//                 </div>

//                 <div className="form-group mb-3">
//                   <label>Date of Birth</label>
//                   <input
//                     type="date"
//                     className="form-control"
//                     {...register(`coVisitors.${index}.dob`, { required: true })}
//                   />
//                   {errors.coVisitors?.[index]?.dob && (
//                     <p className="text-danger">Date of birth is required</p>
//                   )}
//                 </div>

//                 <div className="form-group col-md-6">
//                   <label className="form-label">Gender </label>
//                   <br />

//                   <div className="form-check custom-checkbox mb-2 form-check-inline">
//                     <input
//                       className="form-check-input"
//                       type="radio"
//                       id={`MaleRadio${index}`}
//                       value="Male"
//                       {...register(`coVisitors.${index}.gender`, {
//                         required: true,
//                       })}
//                     />
//                     <label
//                       className="form-check-label"
//                       htmlFor={`MaleRadio${index}`}
//                     >
//                       Male
//                     </label>
//                   </div>
//                   <div className="form-check custom-checkbox mb-2 form-check-inline">
//                     <input
//                       type="radio"
//                       className="form-check-input"
//                       id={`FemaleRadio${index}`}
//                       value="Female"
//                       {...register(`coVisitors.${index}.gender`, {
//                         required: true,
//                       })}
//                     />
//                     <label
//                       className="form-check-label"
//                       htmlFor={`FemaleRadio${index}`}
//                     >
//                       Female
//                     </label>
//                   </div>
//                   {errors.coVisitors?.[index]?.gender && (
//                     <p className="text-danger">Select gender</p>
//                   )}
//                 </div>

//                 {/* Profile Photo */}
//                 <div className="form-group col-md-6 mb-3">
//                   <label className="form-label">Profile Photo (JPG/PNG)</label>
//                   <div
//                     className="file-upload-box"
//                     onClick={() =>
//                       document
//                         .getElementById(`profilePhotoInput${index}`)
//                         .click()
//                     }
//                   >
//                     {profilePhotoPreviews[index] ? (
//                       <img
//                         src={profilePhotoPreviews[index]}
//                         alt="Profile Preview"
//                       />
//                     ) : (
//                       <p>Click to upload</p>
//                     )}
//                   </div>
//                   <input
//                     id={`profilePhotoInput${index}`}
//                     type="file"
//                     className="form-control"
//                     style={{ display: "none" }}
//                     accept="image/jpeg, image/png"
//                     {...register(`coVisitors.${index}.profile_photo`, {
//                       required: true,
//                     })}
//                     onChange={(e) => handleProfilePhotoChange(index, e)}
//                   />
//                   {errors.coVisitors?.[index]?.profile_photo && (
//                     <p className="text-danger">Profile Photo is required</p>
//                   )}
//                 </div>

//                 {/* aadhaar photo */}
//                 <div className="form-group col-md-6 mb-3">
//                   <label className="form-label">Aadhaar Photo (JPG/PNG)</label>
//                   <div
//                     className="file-upload-box"
//                     onClick={() =>
//                       document
//                         .getElementById(`aadhaarPhotoInput${index}`)
//                         .click()
//                     }
//                   >
//                     {aadhaarPhotoPreviews[index] ? (
//                       <img
//                         src={aadhaarPhotoPreviews[index]}
//                         alt="Aadhaar Preview"
//                       />
//                     ) : (
//                       <p>Click to upload</p>
//                     )}
//                   </div>
//                   <input
//                     id={`aadhaarPhotoInput${index}`}
//                     type="file"
//                     className="form-control"
//                     style={{ display: "none" }}
//                     accept="image/jpeg, image/png"
//                     {...register(`coVisitors.${index}.aadhaar_photo`, {
//                       required: true,
//                     })}
//                     onChange={(e) => handleAadhaarPhotoChange(index, e)}
//                   />
//                   {errors.coVisitors?.[index]?.aadhaar_photo && (
//                     <p className="text-danger">Aadhaar Photo is required</p>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>

//       {/* Returnable Items */}
//       <div className="d-flex align-items-center py-3">
//         <h4 className="mb-0 me-2">Returnable Items</h4>
//         <button
//           type="button"
//           className="btn btn-sm btn-outline-primary"
//           onClick={() => appendReturnableItem({})}
//         >
//           Add Item
//         </button>
//       </div>

//       <div className="row">
//         {returnableItems.map((item, index) => (
//           <div key={item.id} className="col-md-6 col-sm-12 mb-2">
//             <div className="border rounded">
//               <div className="d-flex align-items-center justify-content-between border-bottom p-2">
//                 <h6 className="mb-0">Item {index + 1}</h6>
//                 <button
//                   type="button"
//                   className="btn btn-outline-danger btn-sm btn-circle"
//                   onClick={() => removeReturnableItem(index)}
//                 >
//                   <i className="fa fa-solid fa-x"></i>
//                 </button>
//               </div>
//               <div className="row px-2 py-2">
//                 <div className="col-md-6 col-sm-12">
//                   <div className="form-group">
//                     <label>Item Name</label>
//                     <input
//                       className="form-control"
//                       {...register(`returnableItems.${index}.item_name`, {
//                         required: true,
//                       })}
//                     />
//                     {errors.returnableItems?.[index]?.item_name && (
//                       <p className="text-danger">Item name is required</p>
//                     )}
//                   </div>

//                   <div className="form-group">
//                     <label>Item Brand</label>
//                     <input
//                       className="form-control"
//                       {...register(`returnableItems.${index}.item_brand`, {
//                         required: true,
//                       })}
//                     />
//                     {errors.returnableItems?.[index]?.item_brand && (
//                       <p className="text-danger">Item Brand is required</p>
//                     )}
//                   </div>

//                   <div className="form-group">
//                     <label>Item Description</label>
//                     <input
//                       className="form-control"
//                       {...register(
//                         `returnableItems.${index}.item_description`,
//                         {
//                           required: true,
//                         }
//                       )}
//                     />
//                     {errors.returnableItems?.[index]?.item_description && (
//                       <p className="text-danger">
//                         Item Description is required
//                       </p>
//                     )}
//                   </div>
//                 </div>
//                 <div className="col-md-6 col-sm-12 my-3">
//                   <label className="form-label">Item Photo (JPG/PNG)</label>
//                   <div
//                     className="file-upload-box"
//                     onClick={() =>
//                       document.getElementById(`itemPhotoInput${index}`).click()
//                     }
//                   >
//                     {profilePhotoPreviews[index] ? (
//                       <img src={itemPhotoPreviews[index]} alt="Item Preview" />
//                     ) : (
//                       <p>Click to upload</p>
//                     )}
//                   </div>
//                   <input
//                     id={`itemPhotoInput${index}`}
//                     type="file"
//                     className="form-control"
//                     style={{ display: "none" }}
//                     accept="image/jpeg, image/png"
//                     {...register(`returnableItems.${index}.item_photo`, {
//                       required: true,
//                     })}
//                     onChange={(e) => handleItemPhotoChange(index, e)}
//                   />
//                   {errors.returnableItems?.[index]?.item_photo && (
//                     <p className="text-danger">Item Photo is required</p>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//       <div className="form-group my-3">
//         <label>Purpose of Visiting</label>
//         <textarea
//           className="form-control"
//           placeholder="Enter purpose of visiting"
//           {...register("purpose_of_visiting", {
//             required: "Purpose is required",
//           })}
//         />
//         <p className="text-danger">{errors.purpose_of_visiting?.message}</p>
//       </div>
//       <button type="submit" className="btn btn-primary ">
//         Submit Gatepass
//       </button>
//     </form>
//   );
// };

// export default GatepassForm;
