import axiosInstance from './AxiosInstance';
const userUrl = "api/user"
const visitorUrl = "api/visitor"
// user
export function getUserListApi(page, page_size, query) {
    return axiosInstance.get(`${userUrl}?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getUserApi(id) {
    return axiosInstance.get(`${userUrl}/${id}`);
}

export function createUserApi(data) {
    return axiosInstance.post(userUrl, data);
}

export function updateUserApi(data) {
    return axiosInstance.put(userUrl, data);
}


export function getVisitorListApi(page, page_size, query) {
    return axiosInstance.get(`${visitorUrl}?page=${page}&page_size=${page_size}&query=${query}`);
}

export function getVisitorApi(id) {
    return axiosInstance.get(`${visitorUrl}/${id}`);
}

export function createVisitorApi(data) {
    return axiosInstance.post(visitorUrl, data);
}

export function updateVisitorApi(data) {
    return axiosInstance.put(visitorUrl, data);
}
