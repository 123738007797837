import React from "react";
import { connect } from "react-redux";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";

/// Css
import "./index.css";


/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";


/// Dashboard
import Home from "./components/Dashboard/Home";
import GatePage from "./components/Gate";
// import ViewLayout from "./components/Layout/ViewLayout";
import DevicePage from "./components/Device";
import UserPage from "./components/User";
import UserDetailsPage from "./components/User/UserDetails";
import DepartmentDesignationPage from "./components/DepartmentDesignation";
import VisitorPage from "./components/Visitor";
import VisitorDetailsPage from "./components/Visitor/VisitorDetails";

/// Pages
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Login from "./pages/Login"
import Registration from "./pages/Registration"
import ForgotPassword from "./pages/ForgotPassword"
import GatepassPage from "./components/Gatepass";
import AddGatepassPage from "./components/Gatepass/AddGatepass";
import GatepassDetailsPage from "./components/Gatepass/GatepassDetails";

import logo from '../images/logo/logo.png';
import logoText from '../images/logo/logo_text.png';

const allroutes = [
  // Dashboard
  { url: "dashboard", element: <Home />, accessRole: ["SuperAdmin", "Admin", "Visitor"] },
  { url: "gate", element: <GatePage />, accessRole: ["SuperAdmin"] },
  { url: "gatepass", element: <GatepassPage />, accessRole: ["SuperAdmin", "Admin", "Visitor"] },
  { url: "gatepass/:id", element: <GatepassDetailsPage />, accessRole: ["SuperAdmin", "Admin", "Visitor"] },
  { url: "apply-gatepass", element: <AddGatepassPage />, accessRole: ["Visitor"] },
  { url: "user", element: <UserPage />, accessRole: ["SuperAdmin"] },
  { url: "user/:id", element: <UserDetailsPage />, accessRole: ["SuperAdmin"] },
  { url: "visitor", element: <VisitorPage />, accessRole: ["SuperAdmin", "Admin"] },
  { url: "visitor/:id", element: <VisitorDetailsPage />, accessRole: ["SuperAdmin", "Admin"] },
  { url: "device", element: <DevicePage />, accessRole: ["SuperAdmin"] },
  { url: "department-designation", element: <DepartmentDesignationPage />, accessRole: ["SuperAdmin"] },
];

const FlashScreen = () => {
  return (
    <div className="flash-screen">
      <div className="flash-body">
        <img src={logo} alt="" width={'50px'} style={{ marginRight: '0.6rem' }} />
        <img src={logoText} alt="" width={'150px'} />
      </div>
    </div>
  )
}

const Markup = ({ isAuthenticated, isCheckingAuth, role, sideMenu }) => {
  const NotFound = () => <Error404 />

  function MainLayout() {
    return (
      <div id="main-wrapper" className={`show ${sideMenu ? "menu-toggle" : ""}`}>
        <Nav />
        <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
          <Outlet />
        </div>
        <Footer />
      </div>
    )
  }

  const HandleUserAuthentication = ({ data }) => {
    if (!isAuthenticated) {
      return <Navigate to='/' />
    } else if (data.accessRole.includes(role)) {
      return data.element
    } return <Error403 />
  };

  return (isCheckingAuth ? <FlashScreen /> :
    <>
      <Routes>
        <Route path='/' exact element={isAuthenticated ? <Navigate to='/dashboard' /> : <Login />} />
        <Route path='/register' exact element={isAuthenticated ? <Navigate to='/dashboard' /> : <Registration />} />
        <Route path='/forgot-password' exact element={isAuthenticated ? <Navigate to='/dashboard' /> : <ForgotPassword />} />
        <Route element={<MainLayout />} >
          {allroutes.map((data, i) => (
            <Route
              key={i}
              exact
              path={`${data.url}`}
              element={<HandleUserAuthentication data={data} />}
            />
          ))}
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
      <ScrollToTop />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    role: state.auth.user?.role || "User",
    sideMenu: state.sideMenu,
    isAuthenticated: state.auth.user,
    isCheckingAuth: state.auth.isCheckingAuth
  };
};

export default connect(mapStateToProps)(Markup);
