import { SVGICON } from "../../constant/theme";

export const MenuList = [

    {
        title: 'Dashboard',
        iconStyle: SVGICON.MenuDashboard,
        to: '/dashboard',
        accessRole: ["SuperAdmin", "Admin", "Visitor"]
    },
    {
        title: 'Gatepass',
        iconStyle: SVGICON.MenuMaterial,
        to: '/gatepass',
        accessRole: ["SuperAdmin", "Admin", "Visitor"]
    },
    {
        title: 'User',
        iconStyle: SVGICON.MenuUsers,
        to: '/user',
        accessRole: ["SuperAdmin"]
    }, 
    {
        title: 'Visitor',
        iconStyle: SVGICON.MenuUsers,
        to: '/visitor',
        accessRole: ["SuperAdmin", "Admin"]
    },  
    {
        title: 'Gate',
        iconStyle: SVGICON.MenuLayout,
        to: '/gate',
        accessRole: ["SuperAdmin"]
    },
    {
        title: 'Device',
        iconStyle: SVGICON.MenuDevice,
        to: '/device',
        accessRole: ["SuperAdmin"]
    },
    {
        title: 'Dept. & Designation',
        iconStyle: SVGICON.MenuMedia,
        to: '/department-designation',
        accessRole: ["SuperAdmin"]
    },
]