import React, { useState } from "react";

const PasswordInput = ({
  onChange,
  errorMessage = "",
  fieldName = "password",
  fieldLabel = "Password",
    ...props
}) => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => setShowPassword(!showPassword);
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPassword(value);
    onChange(name, value);
  };

  return (
    <div className="form-group">
      <label className="form-label" htmlFor={fieldName}>
        {fieldLabel}
      </label>
      <div className="input-group">
        <input
          type={showPassword ? "text" : "password"}
          className="form-control border border-end-0"
          placeholder="Enter Password"
          {...props}
          value={password}
          id={fieldName}
          name={fieldName}
          onChange={handlePasswordChange}
        />
        <button
          className="btn border border-start-0 border-light-subtle pb-2"
          type="button"
          onClick={togglePassword}
        >
          {showPassword ? (
            <i className="fa-solid fa-eye-slash"></i>
          ) : (
            <i className="fa-solid fa-eye"></i>
          )}
        </button>
      </div>
      <p className="text-danger">{errorMessage}</p>
    </div>
  );
};

export default PasswordInput;
