import {
    CONFIRMED_CREATE_PRODUCT_ACTION,
    CONFIRMED_DELETE_PRODUCT_ACTION,
    CONFIRMED_EDIT_PRODUCT_ACTION,
    CONFIRMED_GET_PRODUCT,
    CONFIRMED_GET_PRODUCT_LIST,
    CREATE_PRODUCT_ACTION,
    CONFIRMED_PRODUCT_STOCK_IN_ACTION,
    LOADING_PRODUCT_ACTION,
    MODAL_TOGGLE_ACTION,
    SHOW_TOAST_ERROR,
    HANDLE_SELETED_PRODUCT,
    LOADING_SINGLE_PRODUCT_ACTION
} from '../actions/ProductTypes';
import { toast } from 'react-toastify';

const initialState = {
    products: [],
    totals: 0,
    selectProduct: null,
    productStockRacks: [],
    productStockHistory: [],
    isModalOpen: false,
    modalType: "Add",
    isLoading: false,
    isLoadingSingleProduct: false
};


function ProductReducer(state = initialState, actions) {

    if (actions.type === LOADING_PRODUCT_ACTION) {
        return {
            ...state,
            isLoading: actions.payload,
        };
    }else if (actions.type === LOADING_SINGLE_PRODUCT_ACTION) {
        return {
            ...state,
            isLoadingSingleProduct: actions.payload,
        };
    }

    else if (actions.type === MODAL_TOGGLE_ACTION) {
        return {
            ...state,
            isModalOpen: !state.isModalOpen,
            modalType: actions.payload
        };
    }

    else if (actions.type === CREATE_PRODUCT_ACTION) {
        const post = {
            id: Math.random(),
            title: 'Post Title 2asdasd',
            description: 'Sample Description 2asdasdas',
        };

        const posts = [...state.posts];
        posts.push(post);
        return {
            ...state,
            posts,
        };
    }

    else if (actions.type === CONFIRMED_DELETE_PRODUCT_ACTION) {
        const products = [...state.products];
        const rackIndex = products.findIndex(
            (rack) => rack.id === actions.payload,
        );
        products.splice(rackIndex, 1);
        toast.success("Product Deleted Successfully")
        return {
            ...state,
            products,
            isModalOpen: !state.isModalOpen
        };
    }

    else if (actions.type === CONFIRMED_EDIT_PRODUCT_ACTION) {

        toast.success("Product Updated successfully")
        return {
            ...state,
            selectProduct: actions.payload,
            isModalOpen: !state.isModalOpen
        };
    }

    else if (actions.type === CONFIRMED_CREATE_PRODUCT_ACTION) {
        toast.success("Product created successfully")
        return {
            ...state,
            products: [actions.payload, ...state.products],
            isModalOpen: !state.isModalOpen,
        };
    }

    else if (actions.type === CONFIRMED_GET_PRODUCT) {
        const { product, racks, histories } = actions.payload
        return {
            ...state,
            selectProduct: product,
            productStockRacks: racks,
            productStockHistory: histories,
            isLoadingSingleProduct:false
        };
    }

    else if (actions.type === CONFIRMED_PRODUCT_STOCK_IN_ACTION) {
        toast.success("Stock In successful")
        const { product_net_quantity, rack, history } = actions.payload
        let productStockRacks = [...state.productStockRacks]
        const rackIndex = productStockRacks.findIndex(
            (prack) => prack.id === rack.id,
        );
        if (rackIndex !== -1) {
            productStockRacks[rackIndex] = { ...productStockRacks[rackIndex], net_quantity: productStockRacks[rackIndex].net_quantity + rack.quantity }
        } else {
            const new_rack = { id: rack.id, rack_no: rack.rack_no, net_quantity: rack.quantity }
            productStockRacks = [new_rack, ...productStockRacks]
        }

        return {
            ...state,
            selectProduct: { ...state.selectProduct, net_quantity: product_net_quantity },
            productStockRacks,
            productStockHistory: [history, ...state.productStockHistory],
            isModalOpen: !state.isModalOpen
        };
    }

    else if (actions.type === HANDLE_SELETED_PRODUCT) {
        let selected = null
        if (actions.payload) {
            selected = state.products.filter(itm => itm.id === actions.payload)[0]
        }
        return {
            ...state,
            selectProduct: selected,
        };
    }

    else if (actions.type === CONFIRMED_GET_PRODUCT_LIST) {
        if (actions.payload) {
            return {
                ...state,
                products: actions.payload.data,
                totals: actions.payload.total,
                isLoading:false
            };
        } else {
            return {
                ...state
            };
        }
    }

    else if (actions.type === SHOW_TOAST_ERROR) {
        toast.error(actions.payload)
        return {
            ...state,
        };
    }

    return state;
}

export default ProductReducer;