// /// Menu
// import React, { useContext, useReducer, useState, useEffect } from "react";
// import Collapse from 'react-bootstrap/Collapse';


// /// Link
// import { Link } from "react-router-dom";

// import { MenuList } from './Menu';
// import { useScrollPosition } from "@n8tb1t/use-scroll-position";
// import { ThemeContext } from "../../../context/ThemeContext";
// import { SVGICON } from "../../constant/theme";
// import { connect } from "react-redux";
// import { useDispatch } from 'react-redux';
// import { toast } from "react-toastify";
// import LogoutPage from './Logout';
// import { updateProfileAction } from "../../../store/actions/AuthActions";
// import { updateProfile } from "../../../services/ProductService";
// import { Form, Offcanvas } from "react-bootstrap";
// import { getInitialChar } from "../../../utils";
// const reducer = (previousState, updatedState) => ({
//   ...previousState,
//   ...updatedState,
// });

// const initialState = {
//   active: "/dashboard",
//   activeSubmenu: "",
// }

// const SideBar = ({ user, sideMenu }) => {
//   const {
//     iconHover,
//     sidebarposition,
//     headerposition,
//     sidebarLayout,
//   } = useContext(ThemeContext);
//   const dispatch = useDispatch()

//   const [state, setState] = useReducer(reducer, initialState);
//   //For scroll
//   const [hideOnScroll, setHideOnScroll] = useState(true)
//   const [profileModalOpen, setProfileModalOpen] = useState(false);
//   const [empForm, setEmpForm] = useState({ name: '', password: '' })
//   useScrollPosition(
//     ({ prevPos, currPos }) => {
//       const isShow = currPos.y > prevPos.y
//       if (isShow !== hideOnScroll) {
//         setHideOnScroll(isShow)
//       }
//     },
//     [hideOnScroll]
//   )

//   useEffect(() => {
//     const path = window.location.pathname
//     setState({ active: path });
//   }, [])


//   const handleMenuActive = status => {
//     setState({ active: status });
//   }

//   const handleSubmenuActive = (status) => {
//     setState({ activeSubmenu: status })
//   }
//   // Menu dropdown list End

//   const toggleProfileModal = () => {
//     setEmpForm({ name: user?.name, password: '' })
//     setProfileModalOpen(!profileModalOpen)
//   }

//   const handleChangeAdd = (e) => {
//     const { name, value } = e.target
//     setEmpForm({ ...empForm, [name]: value })
//   }

//   const handleSubmit = (e) => {
//     e.preventDefault()
//     updateProfile(empForm).then((response) => {
//       dispatch(updateProfileAction(response.data.data))
//       toast.success(response.data.message)
//     }).catch((error) => {
//       toast.error(error.response.data.message)
//     });
//   }

//   /// Path
//   let path = window.location.pathname;
//   path = path.split("/");
//   path = path[path.length - 1];

//   return (
//     <div
//       className={`deznav  border-right ${iconHover} ${sidebarposition.value === "fixed" &&
//         sidebarLayout.value === "horizontal" &&
//         headerposition.value === "static"
//         ? hideOnScroll > 120
//           ? "fixed"
//           : ""
//         : ""
//         }`}
//     >
//       <div className="deznav-scroll">
//         <ul className="metismenu" id="menu">
//           {MenuList.map((data, index) => {
//             let menuClass = data.classsChange;
//             if (menuClass === "menu-title") {
//               return (
//                 <li className={menuClass} key={index} >{data.title}</li>
//               )
//             } else {
//               return (
//                 <li className={` ${state.active === data.to ? 'mm-active' : ''}`}
//                   key={index}
//                 >

//                   {data.content && data.content.length > 0 ?
//                     <>
//                       <Link to={"#"}
//                         className="has-arrow"
//                         onClick={() => { handleMenuActive(data.title) }}
//                       >
//                         <div className="menu-icon">
//                           {data.iconStyle}
//                         </div>
//                         {" "}<span className="nav-text">{data.title}
//                           {
//                             data.update && data.update.length > 0 ?
//                               <span className="badge badge-xs badge-danger ms-2">{data.update}</span>
//                               :
//                               ''
//                           }
//                         </span>
//                       </Link>
//                       <Collapse in={!!(state.active === data.title)}>
//                         <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
//                           {data.content && data.content.map((data, index) => {
//                             return (
//                               <li key={index}
//                                 className={`${state.activeSubmenu === data.title ? "mm-active" : ""}`}
//                               >
//                                 {data.content && data.content.length > 0 ?
//                                   <>
//                                     <Link to={data.to} className={data.hasMenu ? 'has-arrow' : ''}
//                                       onClick={() => { handleSubmenuActive(data.title) }}
//                                     >
//                                       {data.title}
//                                     </Link>
//                                     <Collapse in={!!(state.activeSubmenu === data.title)}>
//                                       <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
//                                         {data.content && data.content.map((data, ind) => {
//                                           return (
//                                             <li key={ind}>
//                                               <Link className={`${path === data.to ? "mm-active" : ""}`} to={data.to}>{data.title}</Link>
//                                             </li>
//                                           )
//                                         })}
//                                       </ul>
//                                     </Collapse>
//                                   </>
//                                   :
//                                   <Link to={data.to}>
//                                     {data.title}
//                                   </Link>
//                                 }

//                               </li>

//                             )
//                           })}
//                         </ul>
//                       </Collapse>
//                     </>
//                     :
//                     <Link to={data.to} onClick={() => { handleMenuActive(data.to) }}>
//                       <div className="menu-icon">
//                         {data.iconStyle}
//                       </div>
//                       {" "}<span className="nav-text">{data.title}</span>
//                       {
//                         data.update && data.update.length > 0 ?
//                           <span className="badge badge-xs badge-danger ms-2">{data.update}</span>
//                           :
//                           ''
//                       }
//                     </Link>
//                   }

//                 </li>
//               )
//             }
//           })}
//         </ul>
//         <div className="user-profile">
//           <div className="card user-profile-card">
//             <div className="user-profile-item">
//               <div className="user-profile-icon">
//                 <span className="avatar-text">{getInitialChar(`${user?.first_name} ${user?.last_name}` || '')}</span>
//               </div>
//               <div className="user-profile-text user-name">
//                 <h6>{user?.first_name} {user?.last_name}</h6>
//                 <span>{user?.email}</span>
//               </div>
//             </div>
//             <Link onClick={toggleProfileModal} className="user-profile-item">
//               <span className="user-profile-icon">{SVGICON.User}</span>
//               <span className="user-profile-text">Profile</span>
//             </Link>
//             <LogoutPage />
//           </div>
//         </div>
//         {/* end user profile end */}
//       </div>
//       <Offcanvas show={profileModalOpen}
//         onHide={() => toggleProfileModal()}
//         className="offcanvas-end" placement='end'>
//         <div className="offcanvas-header">
//           <h5 className="modal-title" id="#gridSystemModal">Profile</h5>
//           <button type="button" className="btn-close"
//             onClick={() => toggleProfileModal()}>
//           </button>
//         </div>
//         <div className="offcanvas-body">
//           <div className="container">

//             <div className="card card-body mb-3">
//               <h5>Profile Details</h5>
//               <ul>
//                 <li className="my-2"><strong>Name</strong>: {user?.name}</li>
//                 <li className="my-2"><strong>Email</strong>: {user?.email}</li>
//                 <li className="my-2"><strong>Role</strong>: {user?.role}</li>
//                 <li className="my-2"><strong>Status</strong>: {user?.status}</li>
//                 {user?.role !== "Admin" ? (
//                   <>
//                     <li className="my-2"><strong>Phone</strong>: {user?.mobile_number}</li>
//                     <li className="my-2"><strong>Employee ID</strong>: {user?.employee_id}</li>
//                     <li className="my-2"><strong>Department</strong>: {user?.department?.name}</li>
//                     <li className="my-2"><strong>Designation</strong>: {user?.designation?.name}</li>
//                   </>
//                 ) : null}
//               </ul>
//             </div>

//             <Form onSubmit={(e) => handleSubmit(e)} className="card card-body">
//               <div className="row">
//                 <h5>Update Profile</h5>
//                 <div className="col-xl-12">
//                   <div className="mb-3">
//                     <label htmlFor="name" className="form-label">Name <span className="text-danger">*</span></label>
//                     <input type="text" autoFocus className="form-control" id="name" placeholder="Enter Name"
//                       value={empForm.name} name='name' onChange={handleChangeAdd} required />
//                   </div>

//                   <div className="mb-3">
//                     <label htmlFor="password" className="form-label">New Password <span>(Optional)</span></label>
//                     <input type="text" className="form-control" id="password" placeholder="Enter New Password"
//                       value={empForm.password} name='password' onChange={handleChangeAdd} />
//                   </div>
//                 </div>
//               </div>
//               <div>
//                 <button type="submit" className="btn btn-sm btn-primary me-1">Submit</button>
//                 <button type="button" onClick={() => toggleProfileModal()} className="btn btn-sm btn-danger light ms-1">Close</button>
//               </div>
//             </Form>
//           </div>
//         </div>
//       </Offcanvas>
//     </div>
//   );
// };
// const mapStateToProps = (state) => {
//   return {
//     user: state.auth.user,
//     sideMenu: state.sideMenu
//   };
// };

// export default connect(mapStateToProps)(SideBar);


import React, { useContext, useReducer, useState, useEffect } from "react";
import Collapse from "react-bootstrap/Collapse";
import { Link, useLocation } from "react-router-dom";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import { SVGICON } from "../../constant/theme";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import LogoutPage from "./Logout";
import { navtoggle } from "../../../store/actions/AuthActions";
import { MenuList } from "./Menu";
import { getInitialChar } from "../../../utils";
const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});


const SideBar = ({ user, role }) => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } = useContext(ThemeContext);
  const dispatch = useDispatch();

  const [state, setState] = useReducer(reducer, { activeMenu: "/dashboard", activeSubmenu: "" });
  const [hideOnScroll, setHideOnScroll] = useState(true);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) {
        setHideOnScroll(isShow);
      }
    },
    [hideOnScroll]
  );

  const location = useLocation();


  useEffect(() => {
    const { menuActive, submenuActive } = getActiveMenuSubmenu(location.pathname)
    if (state.activeMenu !== menuActive || state.activeSubmenu !== submenuActive) {
      setState({ activeMenu: menuActive, activeSubmenu: submenuActive });
      if (!submenuActive) {
        setIsSubMenuOpen(false)
      }
    }
  }, [location]);


  function getActiveMenuSubmenu(url_name) {
    let menuActive = "";
    let submenuActive = "";
    for (const menu of MenuList) {
      if (menu.to === url_name) {
        menuActive = menu.to;
        break;
      } else if (menu.content) {
        for (const submenu of menu.content) {
          if (submenu.to === url_name) {
            menuActive = menu.to;
            submenuActive = submenu.to;
            break;
          }
        }
      }
    }
    return { menuActive, submenuActive }
  }

  const handleMenuActive = (isSubmenu) => {
    if (isSubmenu) {
      setIsSubMenuOpen(!isSubMenuOpen)
    }
    if (window.innerWidth <= 767) {
      dispatch(navtoggle());
    }
  };




  return (
    <div className={`deznav  border-right ${iconHover} ${sidebarposition.value === "fixed" && sidebarLayout.value === "horizontal" && headerposition.value === "static" ? hideOnScroll > 120 ? "fixed" : "" : ""}`}>
      <div className="deznav-scroll">
        <ul className="metismenu" id="menu">
          {MenuList.map((data, index) => (
            <MenuItem key={index}
              data={data} state={state} handleMenuActive={handleMenuActive}
              menuClass={data.classsChange}
              isSubMenuOpen={isSubMenuOpen}
              role={role} />
          ))}
        </ul>
      </div>
      <UserProfile user={user} />
    </div>
  );
};

const MenuItem = ({ data, state, handleMenuActive, handleSubmenuActive, menuClass, isSubMenuOpen, role }) => {
  const checkUrlAccess = () => {
    if (data.accessRole.includes(role)) {
      return true
    } return false
  }

  return checkUrlAccess() ? (
    <li className={` ${state.activeMenu === data.to ? "mm-active" : ""}`}>
      {data.content && data.content.length > 0 ? (
        <>
          <Link to={"#"} className="has-arrow"
            onClick={() => handleMenuActive(true)}>
            <div className="menu-icon">{data.iconStyle}</div>
            <span className="nav-text"> {data.title}</span>
          </Link>
          <Collapse in={isSubMenuOpen}>
            <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
              {data.content && data.content.map((subData, index) => (
                <SubMenuItem key={index} subData={subData} state={state} handleSubmenuActive={handleSubmenuActive} />
              ))}
            </ul>
          </Collapse>
        </>
      ) : (
        <Link to={data.to} onClick={() => handleMenuActive(false)}>
          <div className="menu-icon">{data.iconStyle}</div>
          <span className="nav-text">{data.title}</span>
        </Link>
      )}
    </li>
  ) : null;
};

const SubMenuItem = ({ subData, state }) => {
  return (
    <li className={`${state.activeSubmenu === subData.to ? "mm-active" : ""}`}>
      <Link to={subData.to}>{subData.title}</Link>
    </li>
  );
};

const UserProfile = ({ user }) => {

  return (
    <>
      <div className="user-profile">
        <div className="card user-profile-card">
          <Link className="user-profile-item" to={"/profile"}>
            {user?.profile_photo ? (
              <div className="profile-pic-sm">
                <img src={`${process.env.REACT_APP_BASE_URL}${user.profile_photo}`} alt="img" />
              </div>
            ) : (
              <div className="user-profile-icon">
                <span className="avatar-text">{getInitialChar(`${user?.first_name} ${user?.last_name}` || "")}</span>
              </div>
            )}
            <div className="user-profile-text user-name ms-2">
              <h6>{`${user?.first_name} ${user?.last_name}`}</h6>
              <span>{user?.email}</span>
            </div>
          </Link>
          <Link className="user-profile-item" to={"/profile"}>
            <span className="user-profile-icon">{SVGICON.User}</span>
            <span className="user-profile-text">Profile</span>
          </Link>
          <LogoutPage />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    sideMenu: state.sideMenu,
    role: state.auth.user?.role || "Visitor"
  };
};

export default connect(mapStateToProps)(SideBar);